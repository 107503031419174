import Pill from 'components/Common/Pills/Pill';
import LinkButton from 'components/LinkButton';
import React, { FC } from 'react';

import styles from './SetupMethodCard.module.scss';

interface SetupMethodCardProps {
  label: string;
  buttonLabel: string;
  pillLabel?: string;
  onClick: () => void;
}

const SetupMethodCard: FC<SetupMethodCardProps> = ({ label, buttonLabel, pillLabel, onClick }) => (
  <div className={styles.container}>
    {pillLabel && <Pill label={pillLabel} />}

    <div className={styles.label}>{label}</div>

    <LinkButton isLoading={false} onClick={onClick}>
      {buttonLabel}
    </LinkButton>
  </div>
);

export default SetupMethodCard;
