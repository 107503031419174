import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthSmsCodeResponse, SendSmsCodeResponse } from 'api/AuthApi';
import { authSmsCode, sendSmsCode } from 'thunks';

export interface AuthData {
  phoneId?: string;
  sessionToken?: string;
}

const initialState: AuthData = {};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendSmsCode.fulfilled, (state, { payload }: PayloadAction<SendSmsCodeResponse>) => {
      state.phoneId = payload.phoneId;
    });
    builder.addCase(authSmsCode.fulfilled, (state, { payload }: PayloadAction<AuthSmsCodeResponse>) => {
      if (payload.verified) {
        state.sessionToken = payload.sessionToken;
      }
    });
  },
});

export default auth.reducer;
