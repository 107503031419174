import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getUserPayrollData, getPreQualificationData } from 'thunks';

import { PreQualificationDeclineReason } from 'enums/PreQualificationDeclineReason';
import { GetUserDataResponse } from 'api/UserDataApi';

import { LoanTermsData } from './loanTerms';

export interface PreQualificationResponse {
  passed: boolean | undefined;
  decline_reasons: PreQualificationDeclineReason | undefined;
  output_variables: LoanTermsData | undefined;
}

interface PreQualificationResult {
  isLoading: boolean;
  preQualificationResponse: PreQualificationResponse;
}

const initialState: PreQualificationResult = {
  isLoading: false,
  preQualificationResponse: {
    passed: undefined,
    decline_reasons: undefined,
    output_variables: undefined,
  },
};

const preQualificationResult = createSlice({
  name: 'preQualificationStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPreQualificationData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getPreQualificationData.fulfilled,
      (state, { payload }: PayloadAction<PreQualificationResponse>) => {
        state.isLoading = false;
        state.preQualificationResponse = payload;
      },
    );
    builder.addCase(getPreQualificationData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }: PayloadAction<GetUserDataResponse>) => {
      state.preQualificationResponse.decline_reasons = payload.declineReason;
    });
  },
});

export default preQualificationResult.reducer;
