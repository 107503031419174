export enum Environments {
  Production = 'production',
  Staging = 'staging',
}

export const getEnvironment = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  switch (environment) {
    case 'prod':
      return Environments.Production;
    case 'staging':
      return Environments.Staging;
    default:
      throw Error(`REACT_APP_ENVIRONMENT should be set to prod or staging but is: ${environment}`);
  }
};
