import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentLoanAssistanceData } from 'api/StudentLoanApi';
import { getStudentLoanAssistanceData } from 'thunks';

export interface StudentLoanData {
  eligible?: boolean;
  paymentStartDate?: string;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  monthsToStandardRepayment?: number;
  monthsToPublicServiceRepayment?: number;
}

const initialState: StudentLoanData = {};

const auth = createSlice({
  name: 'studentLoanData',
  initialState,
  reducers: {
    setPaymentStartDate: (state: StudentLoanData, { payload }: PayloadAction<string>) => {
      state.paymentStartDate = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getStudentLoanAssistanceData.fulfilled,
      (state, { payload }: PayloadAction<StudentLoanAssistanceData>) => {
        state.eligible = payload.eligible;
        state.paymentStartDate = payload.paymentStartDate;
        state.maxTerm = payload.maxTerm;
        state.totalBalance = payload.totalBalance;
        state.monthSavings = payload.monthSavings;
        state.monthsToStandardRepayment = payload.monthsToStandardRepayment;
        state.monthsToPublicServiceRepayment = payload.monthsToPublicServiceRepayment;
      },
    );
    builder.addCase(getStudentLoanAssistanceData.rejected, (state) => {
      state.eligible = false;
    });
  },
});

export const { setPaymentStartDate } = auth.actions;

export default auth.reducer;
