import { createSlice } from '@reduxjs/toolkit';
import { getEmployerData, searchEmployers } from 'thunks';
import { Employer } from 'api/EmployersApi';
import { LinkSearchKindType } from 'enums/LinkSearchKindType';

type SearchTerm = string;

interface EmployersState {
  searchTerm: SearchTerm;
  searchResults: Partial<Record<SearchTerm, Employer[]>>;
  searchKind: LinkSearchKindType | null;
  getEmployerData: {
    isLoading: boolean;
  };
}

const initialState: EmployersState = {
  searchTerm: '',
  searchResults: {},
  searchKind: null,
  getEmployerData: {
    isLoading: false,
  },
};

const employers = createSlice({
  name: 'employers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchEmployers.pending, (state, action) => {
      const searchParams = action.meta.arg;
      state.searchTerm = searchParams.name;
      if (state.searchKind !== searchParams.kind) {
        state.searchKind = searchParams.kind;
        state.searchResults = {};
      }
    });

    builder.addCase(searchEmployers.fulfilled, (state, action) => {
      const searchParams = action.meta.arg;
      state.searchResults[searchParams.name] = action.payload;
    });

    builder.addCase(getEmployerData.pending, (state) => {
      state.getEmployerData.isLoading = true;
    });
    builder.addCase(getEmployerData.fulfilled, (state) => {
      state.getEmployerData.isLoading = false;
    });
    builder.addCase(getEmployerData.rejected, (state) => {
      state.getEmployerData.isLoading = false;
    });
  },
});

export default employers.reducer;
