import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserPayrollData } from 'thunks';
import { YourDetailsVariable } from 'enums/LoanFormVariables';
import { GetUserDataResponse } from 'api/UserDataApi';

export interface YourDetailsResponse {
  [YourDetailsVariable.AddressLine1]: string;
  [YourDetailsVariable.AddressLine2]: string;
  [YourDetailsVariable.City]: string;
  [YourDetailsVariable.ZipCode]: string;
  [YourDetailsVariable.ArgyleState]: string;
}

export interface YourDetailInterface {
  [YourDetailsVariable.AddressLine1]: string;
  [YourDetailsVariable.AddressLine2]: string;
  [YourDetailsVariable.City]: string;
  [YourDetailsVariable.ZipCode]: string;
  [YourDetailsVariable.ArgyleState]: string;
}

export const initialState: YourDetailInterface = {
  [YourDetailsVariable.AddressLine1]: '',
  [YourDetailsVariable.AddressLine2]: '',
  [YourDetailsVariable.City]: '',
  [YourDetailsVariable.ZipCode]: '',
  [YourDetailsVariable.ArgyleState]: '',
};

const yourDetails = createSlice({
  name: 'yourDetails',
  initialState,
  reducers: {
    setYourDetailsData: (state: YourDetailInterface, { payload }: PayloadAction<YourDetailInterface>) => {
      state[YourDetailsVariable.AddressLine1] = payload[YourDetailsVariable.AddressLine1];
      state[YourDetailsVariable.AddressLine2] = payload[YourDetailsVariable.AddressLine2];
      state[YourDetailsVariable.City] = payload[YourDetailsVariable.City];
      state[YourDetailsVariable.ZipCode] = payload[YourDetailsVariable.ZipCode];
      state[YourDetailsVariable.ArgyleState] = payload[YourDetailsVariable.ArgyleState];
      analytics.identify({
        address: [
          payload[YourDetailsVariable.AddressLine1],
          payload[YourDetailsVariable.AddressLine2],
          `${payload[YourDetailsVariable.City]}, ${payload[YourDetailsVariable.ArgyleState]} ${
            payload[YourDetailsVariable.ZipCode]
          }`,
        ]
          .filter((line) => line)
          .join('\n'),
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }: PayloadAction<GetUserDataResponse>) => {
      if (payload.isLinkSupported) {
        state[YourDetailsVariable.AddressLine1] = !state[YourDetailsVariable.AddressLine1]
          ? payload.yourDetails[YourDetailsVariable.AddressLine1]
          : state[YourDetailsVariable.AddressLine1];
        state[YourDetailsVariable.AddressLine2] = !state[YourDetailsVariable.AddressLine2]
          ? payload.yourDetails[YourDetailsVariable.AddressLine2]
          : state[YourDetailsVariable.AddressLine2];
        state[YourDetailsVariable.City] = !state[YourDetailsVariable.City]
          ? payload.yourDetails[YourDetailsVariable.City]
          : state[YourDetailsVariable.City];
        state[YourDetailsVariable.ZipCode] = !state[YourDetailsVariable.ZipCode]
          ? payload.yourDetails[YourDetailsVariable.ZipCode]
          : state[YourDetailsVariable.ZipCode];
        state[YourDetailsVariable.ArgyleState] = !state[YourDetailsVariable.ArgyleState]
          ? payload.yourDetails[YourDetailsVariable.ArgyleState]
          : state[YourDetailsVariable.ArgyleState];
      }
    });
  },
});

export const { setYourDetailsData } = yourDetails.actions;

export default yourDetails.reducer;
