import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import FormContainer from 'components/LoanForm/FormContainer';
import StateContainer from 'components/StateContainer';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';

/**
 * Do not remove this component. Docusign will redirect to this page after the user signs the document.
 * As docusign uses pop navigation for the callback, we need this page to be able to use push navigation
 * into the next page.
 */
const WaitingAuthorization = () => {
  const navigate = useNavigate();

  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  useEffect(() => {
    navigate(RoutePath.MakingPayment);
  }, []);

  return (
    <div>
      <FormContainer title="">
        <StateContainer icon={<LoadingSpinner />} title="Waiting Authorization..." />
      </FormContainer>
    </div>
  );
};

export default WaitingAuthorization;
