import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';

interface UnwrappableAction {
  payload: any;
  meta?: any;
  error?: any;
}

export interface AsyncThunkConfig {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
}

const useDispatchWithUnwrap = () => {
  const dispatch = useDispatch();

  const dispatchWithUnwrap = async <Returned, ThunkArg, ThunkApiConfig extends AsyncThunkConfig>(
    action: AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>,
  ) => {
    const result = await dispatch(action);

    return unwrapResult((result as unknown) as UnwrappableAction) as Returned;
  };

  return dispatchWithUnwrap;
};

export default useDispatchWithUnwrap;
