import { FC, useEffect, useState } from 'react';
import { useScript } from 'components/ScriptsLoader/ScriptsLoader';
import { useDispatch } from 'react-redux';
import { disablePayrollProvider } from 'handlers/payrollProviders';
import { PayrollProviderName } from 'api/EmployersApi';
import { getPayDistributionConfigsEncrypt } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { GetApplicationData } from 'handlers/applicationData';

interface ArgyleLoginProps {
  open?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  application: GetApplicationData;
}

const ArgyleWidget: FC<ArgyleLoginProps> = ({ open, onClose, onSuccess, application }) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [encryptedConfig, setEncryptConfig] = useState<string | null>(null);

  const argyleScript = useScript('https://plugin.argyle.io/argyle.web.v3.js', {
    onError: () => dispatch(disablePayrollProvider({ provider: PayrollProviderName.Argyle })),
  });

  useEffect(() => {
    (async () => {
      const { encrypted_config: config } = await dispatchWithUnwrap(getPayDistributionConfigsEncrypt(application.id));
      setEncryptConfig(config);
    })();
  }, []);

  const linkItems = application.employment.employer1.linkItemId
    ? [application.employment.employer1.linkItemId.toLowerCase()]
    : [];
  const openArgyleLogin = () => {
    // @ts-ignore
    const argyleInstance = window.Argyle.create({
      apiHost: process.env.REACT_APP_ARGYLE_API_HOST,
      pluginKey: process.env.REACT_APP_ARGYLE_PLUGIN_KEY,
      customizationId: process.env.REACT_APP_ARGYLE_CUSTOMIZATION_ID,
      linkItems,
      payDistributionItemsOnly: true,
      payDistributionUpdateFlow: true,
      payDistributionConfig: encryptedConfig,
      onPayDistributionSuccess: async () => {
        argyleInstance.close();
        onSuccess && onSuccess();
      },
      onClose,
      onUIEvent: (event: any) => {
        analytics.track(`Argyle: ${event.name}`, event.properties);
      },
    });

    argyleInstance.open();
  };

  useEffect(() => {
    if (open && encryptedConfig && argyleScript.loaded) {
      openArgyleLogin();
    }
  }, [open, encryptedConfig, argyleScript.loaded]);

  return null;
};

export default ArgyleWidget;
