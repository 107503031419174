import { createAsyncThunk } from '@reduxjs/toolkit';
import { AtomicAccessTokenParams, AtomicApi } from 'api/AtomicApi';

const createCreateAtomicAccessTokenThunk = (api: AtomicApi) =>
  createAsyncThunk('createAtomicAccessToken', async (params: AtomicAccessTokenParams) => {
    const { token } = await api.createAccessToken(params);

    return token;
  });

export default createCreateAtomicAccessTokenThunk;
