import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import StateContainer from 'components/StateContainer';
import { getApplicationData } from 'selectors/getApplicationData';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { verifyApplication } from 'thunks';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { FlowComponentType } from 'routes/FlowRouter';
import { VerifyingApplicationResult } from 'enums/FlowNextResults';

import styles from './VerifyingApplication.module.scss';

const VerifyingApplication = ({ handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { id: applicationId } = useSelector(getApplicationData).application!;

  useEffect(() => {
    (async () => {
      const result = await dispatchWithUnwrap(verifyApplication(applicationId));
      if (result.status === ApplicationStatusName.Verification) {
        analytics.track('Verify Application Completed');
        handleNext(VerifyingApplicationResult.Continue);
      } else if (result.status === ApplicationStatusName.Rejected) {
        analytics.track('Verify Application Rejected');
        handleNext(VerifyingApplicationResult.Rejected);
      } else {
        handleNext();
      }
    })();
  }, []);

  return (
    <div className={styles.container}>
      <StateContainer icon={<LoadingSpinner />} title="Verifying..." />
    </div>
  );
};

export default VerifyingApplication;
