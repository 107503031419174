import { isBefore } from 'date-fns';
import { StudentLoanData } from 'handlers/studentLoanData';

export const getRepaymentLabel = (monthDiff: number) =>
  monthDiff > 12
    ? `${Math.floor(monthDiff / 12)} year${monthDiff > 23 ? 's' : ''} earlier`
    : `${monthDiff} month${monthDiff > 1 ? 's' : ''} earlier`;

export const shouldDisplayOffer = ({ paymentStartDate, monthSavings }: StudentLoanData) =>
  paymentStartDate && monthSavings && isBefore(new Date(paymentStartDate), new Date()) && monthSavings > 0;
