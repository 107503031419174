import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import FormNavigation from 'components/FormNavigation';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import GraphCard from 'components/GraphCard/GraphCard';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { FlowComponentType } from 'routes/FlowRouter';
import { HardOfferData, LoanType } from 'handlers/applicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as CreditCardIcon } from 'images/credit-card-icon.svg';
import { ReactComponent as LoanIcon } from 'images/loan-icon.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar-icon.svg';
import { ReactComponent as MoneyCoinsIcon } from 'images/money-coins-icon.svg';
import { ReactComponent as GraphArrowConsolidate } from 'images/arrow-consolidate.svg';
import { ReactComponent as GraphArrowCard } from 'images/arrow-card.svg';
import { ReactComponent as GraphArrowLoan } from 'images/arrow-loan.svg';
import { ReactComponent as GraphArrowLoanAndCard } from 'images/arrow-loan-and-card.svg';
import { ConversionType, trackConversion } from 'utils/analytics';
import { ButtonType } from 'components/Button/Button';
import { HowItWorksResult } from 'enums/FlowNextResults';

import styles from './HowItWorks.module.scss';

enum Debt {
  CARD = 'cards',
  LOAN = 'loans',
  CARD_AND_LOAN = 'cards and loans',
}

const HowItWorks = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const { payOff, keepIt, planneryLoan } = useSelector(getApplicationData).application?.hardOffer as HardOfferData;
  const { applied: cardApplied } = useSelector(getCardData);

  // A customer could have pick some keep debts to consolidate
  const tradelines = [...payOff, ...keepIt];
  const cardDebt = tradelines.filter((debt) => debt.loanType === LoanType.CreditCard);
  const loanDebt = tradelines.filter((debt) => debt.loanType !== LoanType.CreditCard);

  const cardBalance = cardDebt
    .filter((debt) => debt.selectedForConsolidation)
    .reduce((acc, debt) => acc + debt.balanceToConsolidate ?? 0, 0);
  const loanBalance = loanDebt
    .filter((debt) => debt.selectedForConsolidation)
    .reduce((acc, debt) => acc + debt.balanceToConsolidate ?? 0, 0);

  let debt: Debt;
  if (cardBalance <= 0) debt = Debt.LOAN;
  else if (loanBalance <= 0) debt = Debt.CARD;
  else debt = Debt.CARD_AND_LOAN;

  const getDebtTexts = () => {
    switch (debt) {
      case Debt.CARD:
        return {
          card: (
            <span>
              Your Credit Cards: <b>{formatMonetaryAmount(cardBalance)}</b>
            </span>
          ),
        };
      case Debt.LOAN:
        return {
          loan: (
            <span>
              Your Loans: <b>{formatMonetaryAmount(loanBalance)}</b>
            </span>
          ),
        };
      case Debt.CARD_AND_LOAN:
      default:
        return {
          card: (
            <span className={styles.creditCardsText}>
              Cards: <b>{formatMonetaryAmount(cardBalance)}</b>
            </span>
          ),
          loan: (
            <span className={styles.personalLoanText}>
              Loans: <b>{formatMonetaryAmount(loanBalance)}</b>
            </span>
          ),
        };
    }
  };

  const getDebtArrow = () => {
    switch (debt) {
      case Debt.CARD:
        return <GraphArrowCard className={styles.arrow} />;
      case Debt.LOAN:
        return <GraphArrowLoan className={styles.arrow} />;
      case Debt.CARD_AND_LOAN:
      default:
        return <GraphArrowLoanAndCard className={styles.arrowDouble} />;
    }
  };

  const viewOffer = () => {
    trackConversion(ConversionType.LoanAppliedAndQualified);
    handleNext(HowItWorksResult.Continue);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="How it Works" className={styles.formContainer}>
        <div
          className={clsx({
            [styles.row]: debt === Debt.CARD_AND_LOAN,
          })}
        >
          {getDebtTexts().loan && (
            <GraphCard className={styles.loan} icon={LoanIcon}>
              {getDebtTexts().loan}
            </GraphCard>
          )}
          {getDebtTexts().card && (
            <GraphCard className={styles.card} icon={CreditCardIcon}>
              {getDebtTexts().card}
            </GraphCard>
          )}
        </div>
        {getDebtArrow()}
        <GraphCard className={styles.consolidate} icon={MoneyCoinsIcon}>
          <span>
            Your Consolidated Debt = <b>{formatMonetaryAmount(planneryLoan.amount)}</b>. We pay off your {debt}{' '}
          </span>
        </GraphCard>
        <GraphArrowConsolidate className={styles.arrow} />
        <GraphCard className={styles.term} icon={CalendarIcon}>
          <span>
            You pay us one simple monthly payment. Be out of debt in <b>{planneryLoan.term} months</b>
          </span>
        </GraphCard>
      </FormContainer>
      <div className={styles.container}>
        <Button onClick={viewOffer}>View and customize offer</Button>
        {cardApplied && (
          <Button
            type={ButtonType.Inverted}
            className={styles.buttonMarginTop}
            onClick={() => {
              handleNext(HowItWorksResult.BackToYourFinances);
            }}
          >
            Not Now
          </Button>
        )}
      </div>
    </>
  );
};

export default HowItWorks;
