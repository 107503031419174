import { LoanType, Tradeline } from 'handlers/applicationData';

export const MINIMUM_BALANCE = 100;

export const sortTradeLinesByAprAndBalanceDesc = (tradelines: Tradeline[]): Tradeline[] =>
  tradelines.sort((a, b) => {
    const aprDiff: number = b.apr! - a.apr!;
    const balanceDiff: number = b.balance! - a.balance!;

    if (aprDiff === 0) {
      if (a.loanType === LoanType.CreditCard && b.loanType !== LoanType.CreditCard) {
        return -1;
      }

      if (b.loanType === LoanType.CreditCard && a.loanType !== LoanType.CreditCard) {
        return 1;
      }

      return balanceDiff;
    }

    return aprDiff;
  });

export const setTradelinesForConsolidationAndCalculateBalance = (
  tradelines: Tradeline[],
  maxLoanAmount: number,
): Tradeline[] => {
  let debtConsolidationAmount = 0;
  return sortTradeLinesByAprAndBalanceDesc(tradelines).map((tradeline: Tradeline) => {
    let balanceToConsolidate = 0;

    if (debtConsolidationAmount + MINIMUM_BALANCE <= maxLoanAmount) {
      if (debtConsolidationAmount + tradeline.balance! <= maxLoanAmount) {
        debtConsolidationAmount += tradeline.balance!;
        balanceToConsolidate = tradeline.balance!;
      } else {
        balanceToConsolidate = maxLoanAmount - debtConsolidationAmount;
        debtConsolidationAmount = maxLoanAmount;
      }
    }

    return {
      ...tradeline,
      balanceToConsolidate,
      selectedForConsolidation: true,
    };
  });
};
