import { KycStatus } from 'handlers/kyc';

import Api, { FetchMethodType } from './Api';

export interface AlloyCheckResult {
  status: KycStatus;
  journeyApplicationToken: string;
  journeyToken: string;
}

export interface AlloyApi {
  check(applicationId: string): Promise<AlloyCheckResult>;
}

export default class AlloyRestApi extends Api implements AlloyApi {
  public async check(applicationId: string) {
    const { iovation_blackbox: iovationBlackbox } = window as any;

    if (!iovationBlackbox) {
      throw new Error('iovationBlackbox is undefined');
    }

    (window as any).nid('applicationSubmit', 'kycCheck');
    (window as any).nid('closeSession');
    const result = await this.fetch<AlloyCheckResult>(`/kyc/check/${applicationId}`, {
      method: FetchMethodType.POST,
      body: {
        iovationBlackbox,
      },
    });

    return result;
  }
}
