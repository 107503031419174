import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useAutoScroll = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
  }, [location]);
};

export default useAutoScroll;
