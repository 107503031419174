import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YourBirthDateVariable } from 'enums/LoanFormVariables';

export interface YourBirthDateInterface {
  [YourBirthDateVariable.DateOfBirth]: string;
}

export const initialState: YourBirthDateInterface = {
  [YourBirthDateVariable.DateOfBirth]: '',
};

const yourBirthDate = createSlice({
  name: 'yourBirthDate',
  initialState,
  reducers: {
    setYourBirthDateData: (state: YourBirthDateInterface, { payload }: PayloadAction<YourBirthDateInterface>) => {
      state[YourBirthDateVariable.DateOfBirth] = payload[YourBirthDateVariable.DateOfBirth];
      analytics.identify({
        birthday: payload[YourBirthDateVariable.DateOfBirth],
      });
    },
  },
});

export const { setYourBirthDateData } = yourBirthDate.actions;

export default yourBirthDate.reducer;
