import { Tradeline } from 'handlers/applicationData';
import React, { FC } from 'react';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import styles from './TradelineRow.module.scss';

interface Props {
  tradeline: Tradeline;
}

const TradelineRow: FC<Props> = ({ tradeline }) => (
  <div className={styles.tradelineContainer}>
    {tradeline.logoUrl && <img className={styles.tradelineLogo} src={tradeline.logoUrl} alt="logo" />}
    <p className={styles.termTitle}>{tradeline.firm}</p>
    <p className={styles.termValue}>{formatMonetaryAmount(tradeline.balanceToConsolidate, false)}</p>
  </div>
);

export default TradelineRow;
