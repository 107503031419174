import React from 'react';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import Button from 'components/Button';

import styles from './PlanSent.module.scss';

const PlanSent = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const userEmail = useSelector((state: RootState) => state.applicationData.application!.borrowerEmail) ?? `your email`;

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <CheckIcon />
        </div>
        <div className={styles.title}>We sent a copy of your plan to {userEmail}!</div>

        <Button onClick={handleNext}>Done</Button>
      </div>
    </>
  );
};

export default PlanSent;
