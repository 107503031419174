import React from 'react';
import { ReactComponent as CopyIcon } from 'images/copy-icon.svg';
import { getPaymentAccountData } from 'selectors/paymentAccount';
import { useSelector } from 'react-redux';

import BackLink from 'components/BackLink';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { getApplicationData } from 'selectors/getApplicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { copyToClipboard } from 'utils/copyToClipboard';
import MoreInformation from 'components/SetupDeduction/ManualDeduction/components/SetupViaPayroll/components/MoreInformation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './SetupViaPayroll.module.scss';

const SetupViaPayroll = ({ handleNext }: FlowComponentType) => {
  const paymentAccountData = useSelector(getPaymentAccountData).bankAccountData;
  const { amountPerPaycheck, borrowerFirstName, borrowerLastName, fullDepositSwitch } = useSelector(
    getApplicationData,
  ).application!;

  const fullName = `${borrowerFirstName} ${borrowerLastName}`;

  const perPaycheckDeduction = formatMonetaryAmount(amountPerPaycheck, true);

  return (
    <>
      <BackLink />
      <FormContainer
        title="Setup via Your Payroll"
        subtitle={
          !fullDepositSwitch
            ? 'Please login to your payroll provider and add a second destination for your paycheck based on the details below. After you finish, you’ll upload a screenshot to verify this has been done.'
            : 'Please login to your payroll provider and switch your direct deposit to the account shown below. After you finish, you’ll upload a screenshot to verify this has been done.'
        }
      >
        <div className={styles.accountInfoContainer}>
          <div className={styles.accountInfoTitle}>Account Information</div>
          <div className={styles.accountInfoContent}>
            <div className={styles.accountItem}>
              <div className={styles.accountItemName}>Account number: </div>
              {paymentAccountData && (
                <div className={styles.accountItemNumber}>
                  <span>{paymentAccountData.accountNumber}</span>
                  <CopyIcon
                    className={styles.copyIcon}
                    onClick={() => copyToClipboard(paymentAccountData.accountNumber)}
                  />
                </div>
              )}
            </div>
            <div className={styles.accountItem}>
              <div className={styles.accountItemName}>Routing number: </div>
              {paymentAccountData && (
                <div className={styles.accountItemNumber}>
                  <span>{paymentAccountData.routingNumber}</span>
                  <CopyIcon
                    className={styles.copyIcon}
                    onClick={() => copyToClipboard(paymentAccountData.routingNumber)}
                  />
                </div>
              )}
            </div>
            {!fullDepositSwitch && (
              <div className={styles.accountItem}>
                <div className={styles.accountItemName}>Per Paycheck Deduction: </div>
                <div className={styles.accountItemNumber}>
                  <span>{perPaycheckDeduction}</span>
                  <CopyIcon className={styles.copyIcon} onClick={() => copyToClipboard(perPaycheckDeduction)} />
                </div>
              </div>
            )}
          </div>
        </div>

        <MoreInformation
          accountData={{
            employeeName: fullName,
            accountType: paymentAccountData?.accountType,
            bankName: paymentAccountData?.bankName,
          }}
        />

        <Button className={styles.button} onClick={handleNext}>
          Upload Screenshot
        </Button>
      </FormContainer>
    </>
  );
};

export default SetupViaPayroll;
