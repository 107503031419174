import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoanOfferApi } from 'api/LoanOfferApi';

export const createVerifyApplicationThunk = (api: LoanOfferApi) =>
  createAsyncThunk('loanOffer/verifyApplication', async (applicationId: string, thunkApi) => {
    try {
      return await api.verifyApplication(applicationId);
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);
      throw error;
    }
  });
