import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PlaidTokenState {
  publicToken: string;
}

export const initialState: PlaidTokenState = {
  publicToken: '',
};

const plaidToken = createSlice({
  name: 'publicToken',
  initialState,
  reducers: {
    setPublicToken: (state: PlaidTokenState, { payload }: PayloadAction<PlaidTokenState>) => {
      state.publicToken = payload.publicToken;
    },
  },
});

export const { setPublicToken } = plaidToken.actions;

export default plaidToken.reducer;
