import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, DataForUpdatingApplicationFeedback } from 'api/ApplicationDataApi';

export const createFeedbackThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('/application/feedback', async (data: DataForUpdatingApplicationFeedback, thunkApi) => {
    try {
      await api.updateApplicationFeedbackData(data);
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
