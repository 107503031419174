import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Themes } from 'styles/themes/ThemeTypes';

export interface ThemeState {
  selectedTheme: Themes;
}

const initialState: ThemeState = {
  selectedTheme: Themes.DEFAULT,
};

const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setSelectedTheme: (state: ThemeState, { payload }: PayloadAction<Themes>) => {
      state.selectedTheme = payload;
    },
  },
});

export const { setSelectedTheme } = theme.actions;

export default theme.reducer;
