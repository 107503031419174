import React from 'react';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';

import nurseJasonImage from 'images/nurse-jason.png';
import Modal from 'components/Common/Modal';
import { FlowNextResult } from 'enums/FlowNextResults';

import styles from './AreYouSureModal.module.scss';

interface AreYouSureModalProps {
  closeModal: () => void;
  handleDecline: (result?: FlowNextResult) => void;
}

const AreYouSureModal = ({ handleDecline, closeModal }: AreYouSureModalProps) => (
  <Modal closeModal={closeModal}>
    <>
      <p className={styles.title}>Are You Sure?</p>
      <p className={styles.subtitle}>Do your part and help people like Jason find out about Plannery!</p>
      <div className={styles.card}>
        <img className={styles.image} src={nurseJasonImage} alt="Nurse Jason" />
        <p className={styles.testimony}>
          Plannery has been life changing. I'm so thankful to have a rising credit score, and a plan to be debt free in
          3 years.
        </p>

        <p className={styles.name}>Jason</p>
        <p className={styles.credentials}>PA-C</p>
      </div>

      <Button onClick={closeModal} className={styles.button}>
        Share Plannery
      </Button>
      <Button onClick={handleDecline} type={ButtonType.Inverted}>
        Decline Extra Rewards
      </Button>
    </>
  </Modal>
);

export default AreYouSureModal;
