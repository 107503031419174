import React, { FC } from 'react';

import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import InputError from 'components/InputError';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './DatePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps {
  onChange: (date: Date) => void;
  selected?: Date | null;
  className?: string;
  placeHolder?: string;
  label?: string;
  value?: string;
  // isLoading?: boolean;
  disabled?: boolean;
  showMonthYearPicker?: boolean;
  name?: string;
  errorMessage?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DatePicker: FC<DatePickerProps> = ({
  onChange,
  selected,
  className,
  placeHolder,
  label,
  value,
  disabled,
  showMonthYearPicker,
  name,
  errorMessage,
  minDate,
  maxDate,
}) => {
  const { themeStyles } = useLayoutTheme();
  return (
    <div className={clsx(styles.inputContainer, className)}>
      {label && <p className={clsx(styles.label, themeStyles.inputLabel)}>{label}</p>}
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        isClearable={!showMonthYearPicker}
        customInput={showMonthYearPicker ? undefined : <NumberFormat format="##/##/####" />}
        onChange={onChange}
        selected={selected}
        showPopperArrow={!showMonthYearPicker}
        dateFormat={showMonthYearPicker ? 'MM/yyyy' : undefined}
        className={clsx(styles.input, themeStyles.input)}
        placeholderText={placeHolder}
        value={value}
        disabled={disabled}
        showMonthYearPicker={showMonthYearPicker}
        name={name}
        showYearDropdown
        dropdownMode="select"
      />
      {!!errorMessage && <InputError errorMessage={errorMessage} />}
    </div>
  );
};

export default DatePicker;
