import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoanTermsVariables } from 'enums/loanTermsVariables';

export interface LoanTermsData {
  [LoanTermsVariables.InterestRate]?: number | undefined;
  [LoanTermsVariables.MarketAnnualPercentRate]?: number | undefined;
  [LoanTermsVariables.LoanTerm]?: number | undefined;
  [LoanTermsVariables.MinLoanAmount]?: number | undefined;
  [LoanTermsVariables.MaxLoanAmount]?: number | undefined;
  [LoanTermsVariables.MinMonthlyPayment]?: number | undefined;
  [LoanTermsVariables.MaxMonthlyPayment]?: number | undefined;
  [LoanTermsVariables.MarketMinMonthlyPayment]?: number | undefined;
  [LoanTermsVariables.MarketMaxMonthlyPayment]?: number | undefined;
  [LoanTermsVariables.LoanTotalSavings]?: number | undefined;
}

const initialState: LoanTermsData = {
  [LoanTermsVariables.InterestRate]: undefined,
  [LoanTermsVariables.MarketAnnualPercentRate]: undefined,
  [LoanTermsVariables.LoanTerm]: undefined,
  [LoanTermsVariables.MinLoanAmount]: undefined,
  [LoanTermsVariables.MaxLoanAmount]: undefined,
  [LoanTermsVariables.MinMonthlyPayment]: undefined,
  [LoanTermsVariables.MaxMonthlyPayment]: undefined,
  [LoanTermsVariables.MarketMinMonthlyPayment]: undefined,
  [LoanTermsVariables.MarketMaxMonthlyPayment]: undefined,
  [LoanTermsVariables.LoanTotalSavings]: undefined,
};

const loanTermsData = createSlice({
  name: 'loanTerms',
  initialState,
  reducers: {
    setLoanTermsData: (state: LoanTermsData, { payload }: PayloadAction<LoanTermsData>) => {
      state[LoanTermsVariables.InterestRate] = payload[LoanTermsVariables.InterestRate];
      state[LoanTermsVariables.MarketAnnualPercentRate] = payload[LoanTermsVariables.MarketAnnualPercentRate];
      state[LoanTermsVariables.LoanTerm] = payload[LoanTermsVariables.LoanTerm];
      state[LoanTermsVariables.MinLoanAmount] = payload[LoanTermsVariables.MinLoanAmount];
      state[LoanTermsVariables.MaxLoanAmount] = payload[LoanTermsVariables.MaxLoanAmount];
      state[LoanTermsVariables.MinMonthlyPayment] = payload[LoanTermsVariables.MinMonthlyPayment];
      state[LoanTermsVariables.MaxMonthlyPayment] = payload[LoanTermsVariables.MaxMonthlyPayment];
      state[LoanTermsVariables.MarketMinMonthlyPayment] = payload[LoanTermsVariables.MarketMinMonthlyPayment];
      state[LoanTermsVariables.MarketMaxMonthlyPayment] = payload[LoanTermsVariables.MarketMaxMonthlyPayment];
      state[LoanTermsVariables.LoanTotalSavings] = payload[LoanTermsVariables.LoanTotalSavings];
    },
  },
});

export const { setLoanTermsData } = loanTermsData.actions;

export default loanTermsData.reducer;
