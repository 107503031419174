import { PreQualificationData } from 'handlers/preQualificationData';
import { PreQualificationResponse } from 'handlers/preQualificationResult';

import Api, { FetchMethodType } from './Api';

export interface LocationData {
  clientCountryRegion: string;
  clientCity: string;
}

export interface PreQualificationApi {
  getUserLocation(): Promise<LocationData>;
  getPreQualificationResult(preQualificationData: PreQualificationData): Promise<PreQualificationResponse>;
  getLoanAmountResult(preQualificationData: PreQualificationData): Promise<PreQualificationResponse>;
  getCreditScoreResult(preQualificationData: PreQualificationData): Promise<PreQualificationResponse>;
}

export default class PreQualificationRestApi extends Api implements PreQualificationApi {
  public async getUserLocation() {
    const data = await this.fetch<LocationData>(`/prequalification/location`, {
      method: FetchMethodType.GET,
    });

    return data;
  }

  public async getPreQualificationResult(preQualificationData: PreQualificationData) {
    const data = await this.fetch<PreQualificationResponse>(`/prequalification/borrowerState`, {
      method: FetchMethodType.POST,
      body: {
        preQualificationData,
      },
    });

    return data;
  }

  public async getLoanAmountResult(preQualificationData: PreQualificationData) {
    const data = await this.fetch<PreQualificationResponse>(`/prequalification/loanAmountRange`, {
      method: FetchMethodType.POST,
      body: {
        preQualificationData,
      },
    });

    return data;
  }

  public async getCreditScoreResult(preQualificationData: PreQualificationData) {
    const data = await this.fetch<PreQualificationResponse>(`/prequalification/creditScoreRange`, {
      method: FetchMethodType.POST,
      body: {
        preQualificationData,
      },
    });

    return data;
  }
}
