import Api, { FetchMethodType } from 'api/Api';

import { PayrollProviderName } from './EmployersApi';

export interface AtomicApi {
  createAccessToken(params: AtomicAccessTokenParams): Promise<AtomicTokenResponse>;
}

export interface AtomicLinkedAccount {
  accountId: string;
  employerId: string;
  provider: PayrollProviderName;
}

interface AtomicTokenResponse {
  token: string;
}

export interface AtomicAccessTokenParams {
  userId: string;
}

export default class AtomicApiRest extends Api implements AtomicApi {
  public async createAccessToken(params: AtomicAccessTokenParams) {
    return this.fetch<AtomicTokenResponse>('/atomic/accessToken', {
      method: FetchMethodType.POST,
      body: params,
    });
  }
}
