import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Employer } from 'api/ArgyleApi';
import { PayrollProviderName } from 'api/EmployersApi';
import { getUserPayrollData } from 'thunks';

export interface ArgyleCredentials {
  accountId: string;
  linkItemId: string;
  provider: PayrollProviderName;
}

interface ArgyleState {
  isConnecting: boolean;
  isLinkSupported: boolean | undefined;
  selectedEmployerName: Employer | undefined;
  isFirstEmployerPassed: boolean;
  isSecondEmployerPassed: boolean;
}

const initialState: ArgyleState = {
  isConnecting: false,
  isLinkSupported: undefined,
  selectedEmployerName: undefined,
  isFirstEmployerPassed: false,
  isSecondEmployerPassed: false,
};

const argyle = createSlice({
  name: 'argyle',
  initialState,
  reducers: {
    setIsFirstEmployerPassed: (state: ArgyleState, { payload }: PayloadAction<boolean>) => {
      state.isFirstEmployerPassed = payload;
    },
    setIsSecondEmployerPassed: (state: ArgyleState, { payload }: PayloadAction<boolean>) => {
      state.isSecondEmployerPassed = payload;
    },
    setSelectedEmployerName: (state: ArgyleState, { payload }: PayloadAction<Employer | undefined>) => {
      state.selectedEmployerName = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }) => {
      state.isConnecting = false;
      state.isLinkSupported = payload.isLinkSupported;
    });
    builder.addCase(getUserPayrollData.rejected, (state) => {
      state.isConnecting = false;
    });
    builder.addCase(getUserPayrollData.pending, (state) => {
      state.isConnecting = true;
    });
  },
});

export const { setIsFirstEmployerPassed, setIsSecondEmployerPassed, setSelectedEmployerName } = argyle.actions;

export default argyle.reducer;
