import React, { FC } from 'react';
import clsx from 'clsx';

import Loader from 'components/Loader';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './Button.module.scss';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Inverted = 'inverted',
  InputButton = 'input button',
  OptionButton = 'option button',
}

interface ButtonProps {
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  type?: ButtonType;
  id?: string;
}

const getButtonStyles = (buttonType: ButtonType) => {
  switch (buttonType) {
    case ButtonType.Primary:
      return styles.primary;

    case ButtonType.Secondary:
      return styles.secondary;

    case ButtonType.Inverted:
      return styles.inverted;

    case ButtonType.InputButton:
      return styles.inputButton;

    case ButtonType.OptionButton:
      return styles.optionButton;

    default:
      return null as never;
  }
};

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  className,
  isLoading,
  disabled,
  type = ButtonType.Primary,
  id,
}) => {
  const { themeStyles, theme, shouldApplyTheme } = useLayoutTheme();
  return (
    <button
      id={id}
      type="button"
      className={clsx(
        styles.button,
        themeStyles.button,
        getButtonStyles(type),
        className,
        isLoading && themeStyles.isLoading,
        shouldApplyTheme && styles[theme],
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Loader isButtonLoader /> : children}
    </button>
  );
};

export default Button;
