import React, { useEffect, useState } from 'react';

import Button from 'components/Button';

import { BankAccountInfo } from 'api/PlaidApi';
import { updateApplicationCheckingAccount } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import Loader from 'components/Loader';
import { VerificationStepComponent } from 'components/Verification/VerificationNavigation/VerificationNavigation';
import useCurrentFlow from 'hooks/useCurrentFlow';
import FormNavigation from 'components/FormNavigation';
import { CheckingAccountResult } from 'enums/FlowNextResults';

import CheckingAccountRow from './components/CheckingAccountRow';

import styles from './SelectAccount.module.scss';

const SelectAccount = ({ handleNext }: VerificationStepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isMissedPaymentFlow } = useCurrentFlow();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const checkingAccounts = useSelector((state: RootState) => state.bankAccount.bankAccounts)!;
  const { application } = useSelector((state: RootState) => state.applicationData!);

  const [selectedAccount, setSelectedAccount] = useState<string>('');

  const handleAccountChange = (account: BankAccountInfo) => {
    if (account.id === selectedAccount) {
      return;
    }

    setSelectedAccount(account.id);
  };

  const handleContinue = async () => {
    if (selectedAccount) {
      await saveUserSelection();
    }

    handleNext(CheckingAccountResult.Continue);
  };

  const saveUserSelection = async () => {
    await dispatchWithUnwrap(
      updateApplicationCheckingAccount({
        applicationId: application!.id,
        bankAccountId: selectedAccount,
      }),
    );
  };

  useEffect(() => {
    if (checkingAccounts.length) {
      if (checkingAccounts.length > 0) {
        setIsLoading(false);
        setSelectedAccount(checkingAccounts[0].id);
      }
      // Skip checking account list if accounts are less than 2
      if (checkingAccounts.length < 2) {
        handleContinue();
      }
    }
  }, [checkingAccounts]);

  return (
    <>
      {isMissedPaymentFlow && <FormNavigation title="Bank Account" step={3} stepCount={5} />}
      <div className={styles.container}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader color="#795af7" />
          </div>
        ) : (
          <>
            <div className={styles.title}>Select the account the where you deposit your paycheck</div>

            <div className={styles.content}>
              {checkingAccounts.map((account, index) => {
                const { officialName, mask, balance } = account;

                return (
                  <CheckingAccountRow
                    key={`${account.mask}-${index}`}
                    selected={selectedAccount === account.id}
                    onClick={() => handleAccountChange(account)}
                    accountName={officialName}
                    accountNumber={mask}
                    accountBalance={balance}
                  />
                );
              })}
            </div>

            <Button onClick={handleContinue} className={styles.button}>
              Next
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default SelectAccount;
