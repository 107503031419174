import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
// import { ReactComponent as ChevronIcon } from 'images/select-arrow.svg';

import styles from './GiftExpandableCard.module.scss';

interface GiftExpandableCardProps {
  referralAmount: number;
  referralsToComplete: number;
  title: string;
  subtitle: string;
  image: string;
  highlighted: string;
}

const GiftExpandableCard = ({
  referralAmount,
  referralsToComplete,
  title,
  subtitle,
  image,
  highlighted,
}: GiftExpandableCardProps) => {
  const completed = referralAmount >= referralsToComplete;
  const percentage = (referralAmount / referralsToComplete) * 100;
  return (
    <div className={styles.card}>
      <div
        style={{ '--percentage': `${percentage}` } as CSSProperties}
        className={clsx(styles.cardImageContainer, {
          [styles.completed]: completed,
        })}
      >
        <img className={styles.cardImage} src={image} alt="gift" />
        {completed && (
          <div className={styles.checkContainer}>
            <CheckIcon />
          </div>
        )}
      </div>
      <div className={styles.cardMeta}>
        <p className={styles.cardTitle}>
          {referralsToComplete} Referral{referralsToComplete !== 1 ? 's' : ''}: {title}
        </p>
        <p className={styles.cardSubtitle}>{subtitle}</p>
        <p className={styles.cardHighlighted}>{highlighted}</p>
      </div>
      {/* Hide it for now */}
      {/* <ChevronIcon className={styles.dropdownIcon} /> */}
    </div>
  );
};

export default GiftExpandableCard;
