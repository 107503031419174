import {
  ApplicationStatusUpdateResponse,
  CreateApplicationData,
  CreateApplicationResponse,
  GetApplicationAprResponse,
  GetApplicationDataResponse,
  Tradeline,
} from 'handlers/applicationData';
import { BankAccountDataForLos } from 'handlers/bankAccount';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';

import Api, { ErrorMode, FetchMethodType } from './Api';

export interface DataForUpdatingBankAccount {
  applicationId: string;
  bankAccountData: BankAccountDataForLos;
}

export interface DataForUpdatingApplicationStatus {
  applicationId: string;
  applicationStatus: ApplicationStatusName;
}

export interface DataForUpdatingApplicationFeedback {
  applicationId: string;
  experience?: string;
  howDidYouHear?: string;
}

export interface DataForUpdatingApplicationCitizenship {
  applicationId: string;
  citizenship: string;
}

export interface DataForUpdatingApplicationLoanManagement {
  applicationId: string;
  missedPaymentReason?: string;
}

export enum TradelineNewState {
  Selected = 'selected',
  Unselected = 'unselected',
}

export type SelectedTradeline = { tradeline: Tradeline; newState: TradelineNewState };

export interface DataForUpdatingTradelines {
  applicationId: string;
  tradelinesToUpdate: SelectedTradeline[];
}

export interface Employment {
  borrower_employer_name: string;
  annual_income?: number;
  pay_frequency: string;
  payroll_provider: string;
  borrower_hire_date?: string;

  job_title?: string;
  employment_status?: string;
  employment_type?: string;
  hire_datetime?: string;
  base_pay_amount?: number;
  base_pay_period?: string;
}

export interface EmploymentData {
  employment: Employment[];
  isLicensedProfessional: boolean;
  additionalIncome: number;
}

interface UpdatedEmploymentData {
  applicationId: string;
  employmentData: EmploymentData;
}

export interface ApplicationDataApi {
  getApplicationData(applicationId: string): Promise<GetApplicationDataResponse>;
  getApplicationApr(applicationId: string): Promise<GetApplicationAprResponse>;
  createApplication(data: CreateApplicationData): Promise<CreateApplicationResponse>;
  updateApplicationBankAccountData(data: DataForUpdatingBankAccount): Promise<void>;
  updateApplicationStatus(data: DataForUpdatingApplicationStatus): Promise<ApplicationStatusUpdateResponse>;
  updateApplicationCheckingAccount(applicationId: string, bankAccountId: string): Promise<void>;
  updateApplicationFeedbackData(data: DataForUpdatingApplicationFeedback): Promise<void>;
  updateApplicationCitizenship(data: DataForUpdatingApplicationCitizenship): Promise<void>;
  updateApplicationLoanManagementData(data: DataForUpdatingApplicationLoanManagement): Promise<void>;
  updateEmploymentData(data: UpdatedEmploymentData): Promise<GetApplicationDataResponse>;
  updateSelectedTradelines(
    applicationId: string,
    tradelinesToUpdate: SelectedTradeline[],
  ): Promise<GetApplicationDataResponse>;
}

export default class ApplicationDataRestApi extends Api implements ApplicationDataApi {
  public async getApplicationData(applicationId: string) {
    const data = await this.fetch<GetApplicationDataResponse>(`/applicationData/get`, {
      // TODO: workaround. This should be GET, but it throws an error if the body is set.
      method: FetchMethodType.POST,
      body: {
        applicationId,
      },
    });
    return data;
  }

  public async getApplicationApr(applicationId: string) {
    const result = await this.fetch<GetApplicationAprResponse>(`/loanManagement/apr/${applicationId}`, {
      method: FetchMethodType.GET,
    });
    return result;
  }

  public async createApplication(data: CreateApplicationData) {
    const result = await this.fetch<CreateApplicationResponse>(`/applicationData/create`, {
      method: FetchMethodType.POST,
      body: {
        data,
      },
    });
    return result;
  }

  public async updateApplicationBankAccountData(data: DataForUpdatingBankAccount) {
    await this.fetch<void>(`/bankAccount`, {
      method: FetchMethodType.POST,
      body: {
        data,
      },
      errorMode: ErrorMode.DisplayActualMessage,
    });
  }

  public async updateApplicationStatus(data: DataForUpdatingApplicationStatus) {
    const applicationStatus = await this.fetch<ApplicationStatusUpdateResponse>(`/updateApplicationStatus`, {
      method: FetchMethodType.POST,
      body: {
        data,
      },
    });
    return applicationStatus;
  }

  public async updateApplicationCheckingAccount(applicationId: string, bankAccountId: string): Promise<void> {
    await this.fetch<void>(`/bankAccount/primaryCheckingAccount/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: {
        bankAccountId,
      },
    });
  }

  public async updateApplicationFeedbackData(data: DataForUpdatingApplicationFeedback) {
    await this.fetch<void>('/application/feedback', {
      method: FetchMethodType.PATCH,
      body: {
        data,
      },
    });
  }

  public async updateApplicationCitizenship(data: DataForUpdatingApplicationCitizenship) {
    await this.fetch<void>('/application/citizenship', {
      method: FetchMethodType.PATCH,
      body: {
        data,
      },
    });
  }

  public async updateEmploymentData(data: UpdatedEmploymentData): Promise<GetApplicationDataResponse> {
    const { applicationId, employmentData } = data;
    const application = await this.fetch<GetApplicationDataResponse>(`/employers/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: { ...employmentData },
    });

    return application;
  }

  public async updateSelectedTradelines(applicationId: string, tradelinesToUpdate: SelectedTradeline[]) {
    return (await this.fetch(`/reports/debtProfile/${applicationId}`, {
      method: FetchMethodType.PATCH,
      body: {
        tradelinesToUpdate,
      },
    })) as GetApplicationDataResponse;
  }

  public async updateApplicationLoanManagementData(data: DataForUpdatingApplicationLoanManagement) {
    await this.fetch<void>(`/application/loanManagement`, {
      method: FetchMethodType.PATCH,
      body: {
        data,
      },
    });
  }
}
