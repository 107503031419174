import React, { useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import RadioButtonList from 'components/Common/RadioButtonList/RadioButtonList';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import { StudentLoanForgivenessResults } from 'enums/StudentLoanForgivenessFlowResults';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

enum Option {
  Yes = 'yes',
  No = 'no',
}

const options = [
  { id: Option.Yes, label: 'Yes' },
  { id: Option.No, label: 'No' },
];

const ForgivenessAwareness = ({ navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const [selected, setSelected] = useState<StudentLoanForgivenessResults | null>(null);

  const handleNext = () => {
    analytics.track('Student Loan Forgiveness Awareness Selected', { selected });
    onNext(selected!);
  };
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <QuestionsWrapper>
        <>
          <p className={styles.label}>
            Are you aware of a special one-time adjustment to Public Service Loan Forgiveness that ends on December 31?
          </p>

          <RadioButtonList selected={selected} setSelected={setSelected} options={options} />

          <Button disabled={Boolean(!selected)} onClick={handleNext} className={styles.button}>
            Next
          </Button>
        </>
      </QuestionsWrapper>
    </>
  );
};

export default ForgivenessAwareness;
