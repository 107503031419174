import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ManualFlow {
  isManualFlowActivated: boolean;
}

export const initialState: ManualFlow = {
  isManualFlowActivated: false,
};

const manualFlow = createSlice({
  name: 'manualFlow',
  initialState,
  reducers: {
    setManualFlow: (state: ManualFlow, { payload }: PayloadAction<boolean>) => {
      state.isManualFlowActivated = payload;
    },
  },
});

export const { setManualFlow } = manualFlow.actions;

export default manualFlow.reducer;
