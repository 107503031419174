import { useNavigationType } from 'hooks/useNavigate';
import React, { useEffect } from 'react';
import { FlowComponentType } from 'routes/FlowRouter';

const PopBackRedirect = ({ handleNext }: FlowComponentType) => {
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === 'POP') {
      handleNext();
    }
  });

  return <div />;
};

export default PopBackRedirect;
