import { BankAccountVariable } from 'enums/LoanFormVariables';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCheckingAccounts, updateApplicationBankAccountData } from 'thunks';
import { BankAccountInfo } from 'api/PlaidApi';

export interface BankAccountDataForLos {
  [BankAccountVariable.AccountHolder]: string;
  [BankAccountVariable.BankName]: string;
  [BankAccountVariable.RoutingNumber]: string;
  [BankAccountVariable.AccountNumber]: string;
}

interface BankAccountData {
  [BankAccountVariable.AccountHolder]: string;
  [BankAccountVariable.BankName]: string;
  [BankAccountVariable.RoutingNumber]: string;
  [BankAccountVariable.AccountNumber]: string;
}

interface BankAccountState {
  isLoading: boolean;
  bankAccountData?: BankAccountData;
  bankAccounts: BankAccountInfo[];
}

const initialState: BankAccountState = {
  isLoading: false,
  bankAccounts: [],
};

const bankAccount = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    setBankAccountData: (state: BankAccountState, { payload }: PayloadAction<BankAccountData>) => {
      state.bankAccountData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateApplicationBankAccountData.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateApplicationBankAccountData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateApplicationBankAccountData.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCheckingAccounts.fulfilled, (state, { payload }: PayloadAction<BankAccountInfo[]>) => {
      state.bankAccounts = payload;
    });
  },
});

export const { setBankAccountData } = bankAccount.actions;

export default bankAccount.reducer;
