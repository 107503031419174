import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthApi, AuthSessionData, AuthSmsCodeData, SendSmsCodeData } from 'api/AuthApi';

export const createAuthSendSmsCodeThunk = (api: AuthApi) =>
  createAsyncThunk('auth/sendSmsCode', async (data: SendSmsCodeData, thunkApi) => {
    try {
      return await api.sendSmsCode(data);
    } catch (error) {
      thunkApi.rejectWithValue(error?.response?.data);

      throw error;
    }
  });

export const createAuthAuthSmsCodeThunk = (api: AuthApi) =>
  createAsyncThunk('auth/authSmsCode', async (data: AuthSmsCodeData, thunkApi) => {
    try {
      return await api.authSmsCode(data);
    } catch (error) {
      thunkApi.rejectWithValue(error?.response?.data);
      throw error;
    }
  });

export const createAuthAuthSessionThunk = (api: AuthApi) =>
  createAsyncThunk('auth/authSession', async (data: AuthSessionData, thunkApi) => {
    try {
      return await api.authSession(data);
    } catch (error) {
      thunkApi.rejectWithValue(error?.response?.data);
      throw error;
    }
  });
