import React from 'react';
import Button from 'components/Button';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';
import useCurrentFlow from 'hooks/useCurrentFlow';

import styles from './OfferRejected.module.scss';

const resources = [
  {
    name: 'Other lenders',
    items: [
      {
        name: 'Avant',
        link: 'https://www.avant.com/debt-consolidation-loans/',
      },
      {
        name: 'One Main',
        link: 'https://www.onemainfinancial.com/',
      },
      {
        name: 'Americor',
        link: 'https://americor.com/debt-consolidation-loans/',
      },
    ],
  },
  {
    name: 'Resources',
    items: [
      {
        name: 'Improve your Credit Score',
        link: 'https://www.nerdwallet.com/article/finance/raise-credit-score-fast',
      },
    ],
  },
];

const OfferRejected = () => {
  const navigate = useNavigate();

  const { isFinancialCheckupFlow } = useCurrentFlow();

  const handleDone = () => {
    window.location.href = process.env.REACT_APP_PLANNERY_LANDING_URL ?? '';
    // TODO - Where should we redirect the customer?
  };

  const handleBack = () => {
    navigate(RoutePath.YourFinances);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>We're unable to save you money at this time.</div>

        <div className={styles.subtitle}>Try us again next month. Meanwhile, check out these resources:</div>

        {resources.map((resource) => (
          <div key={resource.name.trim()} className={styles.resourceList}>
            <div className={styles.resourceTitle}>{resource.name}</div>

            {resource.items.map((item) => (
              <a
                key={item.name.trim()}
                href={item.link}
                className={styles.resourceName}
                target="_blank"
                rel="noreferrer"
              >
                {item.name}
              </a>
            ))}
          </div>
        ))}

        {isFinancialCheckupFlow ? (
          <Button onClick={handleBack}>Back to Your Finances</Button>
        ) : (
          <Button onClick={handleDone}>Plannery Homepage</Button>
        )}
      </div>
    </>
  );
};

export default OfferRejected;
