import React, { FC, ReactFragment } from 'react';

import Expander from 'components/Expander';

import styles from 'components/LoanBreakdown/LoanBreakdown.module.scss';

interface Props {
  lineContent: string | ReactFragment;
  expandedContent: string | ReactFragment;
}

const LoanBreakdownLine: FC<Props> = ({ lineContent, expandedContent }) => (
  <Expander
    label={lineContent}
    content={expandedContent}
    labelClassname={styles.expanderLabel}
    container={styles.expanderContainer}
    openClassname={styles.expanderOpen}
  />
);

export default LoanBreakdownLine;
