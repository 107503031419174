export enum LoanTermsVariables {
  InterestRate = 'interest_rate',
  MarketAnnualPercentRate = 'market_apr',
  LoanTerm = 'active_loan_term_in_months',
  MinLoanAmount = 'minimum_loan_amount',
  MaxLoanAmount = 'maximum_loan_amount',
  MinMonthlyPayment = 'minimum_monthly_loan_payment',
  MaxMonthlyPayment = 'maximum_monthly_loan_payment',
  MarketMinMonthlyPayment = 'market_minimum_monthly_loan_payment',
  MarketMaxMonthlyPayment = 'market_maximum_monthly_loan_payment',
  LoanTotalSavings = 'loan_total_savings',
}
