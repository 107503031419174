import React, { FC } from 'react';
import clsx from 'clsx';
import BackLink from 'components/BackLink';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './FormNavigation.module.scss';

export interface FormNavigationProps extends React.InputHTMLAttributes<HTMLInputElement> {
  showBackLink?: boolean;
  onBackClick?: () => void;
  title?: string;
  titleAsHeading?: boolean;
  step?: number;
  stepCount?: number;
}

const FormNavigation: FC<FormNavigationProps> = ({
  showBackLink,
  onBackClick,
  title,
  step,
  stepCount,
  className,
  titleAsHeading = false,
}) => {
  const { themeStyles } = useLayoutTheme();
  return (
    <div className={clsx(styles.formNavigation, themeStyles.formNavigation, className)}>
      <div className={styles.holder}>
        {showBackLink !== false && <BackLink className={styles.backLink} onClick={onBackClick} />}
      </div>
      <div
        className={clsx(styles.title, {
          [styles.asHeading]: titleAsHeading,
        })}
      >
        {title}
      </div>
      <div className={styles.steps}>{step && stepCount && `Step ${step} of ${stepCount}`}</div>
    </div>
  );
};

export default FormNavigation;
