import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { PayFrequencyOptionLabel, PayFrequencyOptionValue, setYourTotalIncomeData } from 'handlers/yourTotalIncome';
import { YourTotalIncomeVariable } from 'enums/LoanFormVariables';
import { getMessageForRequiredFields } from 'utils/errors';
import FormContainer from 'components/LoanForm/FormContainer';
import NumberInput from 'components/NumberInput';
import Button from 'components/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { RootState } from 'handlers';
import InputSelect from 'components/InputSelect';
import { InputSelectOption } from 'components/InputSelect/InputSelect';

import styles from './YourTotalIncome.module.scss';

export const PAY_FREQUENCY_OPTIONS = [
  { label: PayFrequencyOptionLabel.Weekly, value: PayFrequencyOptionValue.Weekly },
  { label: PayFrequencyOptionLabel.BiWeekly, value: PayFrequencyOptionValue.BiWeekly },
  { label: PayFrequencyOptionLabel.SemiMonthly, value: PayFrequencyOptionValue.SemiMonthly },
  { label: PayFrequencyOptionLabel.Monthly, value: PayFrequencyOptionValue.Monthly },
  { label: PayFrequencyOptionLabel.Other, value: PayFrequencyOptionValue.Other },
] as InputSelectOption[];

enum YourIncomeInputLabel {
  AnnualIncome = 'Annual Income',
  PayFrequency = 'Pay Frequency',
}

export const ANNUAL_INCOME_MAX_LENGTH = 14;
export const MONETARY_PREFIX = '$';

const YourIncome = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatch = useDispatch();

  const { total_annual_income: totalAnnualIncome } = useSelector((state: RootState) => state.yourTotalIncome);

  const {
    register,
    watch,
    trigger,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [YourTotalIncomeVariable.TotalAnnualIncome]: totalAnnualIncome,
      [YourTotalIncomeVariable.PayFrequency]: PayFrequencyOptionValue.BiWeekly,
    },
  });

  const watcher = watch();

  useEffect(() => {
    register(YourTotalIncomeVariable.TotalAnnualIncome, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.AnnualIncome),
    });
    register(YourTotalIncomeVariable.PayFrequency, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.PayFrequency),
    });
  }, [watcher, register]);

  const handleContinue = () => {
    dispatch(
      setYourTotalIncomeData({
        [YourTotalIncomeVariable.TotalAnnualIncome]: watcher[YourTotalIncomeVariable.TotalAnnualIncome],
        [YourTotalIncomeVariable.PayFrequency]: watcher[YourTotalIncomeVariable.PayFrequency],
      }),
    );
    handleNext();
  };

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="What's your total income?" subtitle="">
        <div className={styles.inputs}>
          <NumberInput
            label="Gross annual income"
            prefix={MONETARY_PREFIX}
            placeholder="$"
            thousandSeparator
            className={styles.formInput}
            name={YourTotalIncomeVariable.TotalAnnualIncome}
            onBlur={() => {
              trigger(YourTotalIncomeVariable.TotalAnnualIncome);
            }}
            onChange={(event) => {
              setValue(YourTotalIncomeVariable.TotalAnnualIncome, event.target.value);
              trigger(YourTotalIncomeVariable.TotalAnnualIncome);
            }}
            value={watcher[YourTotalIncomeVariable.TotalAnnualIncome]}
            maxLength={ANNUAL_INCOME_MAX_LENGTH}
            dataNeuroLabel="income--total"
            hint="Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation."
          />
          <InputSelect
            label="Pay frequency with primary employer"
            options={PAY_FREQUENCY_OPTIONS}
            onChange={(option) => {
              setValue(YourTotalIncomeVariable.PayFrequency, option.value);
              trigger(YourTotalIncomeVariable.PayFrequency);
            }}
            placeholder="Pay Frequency"
            className={styles.formInput}
            name={YourTotalIncomeVariable.PayFrequency}
            value={watcher[YourTotalIncomeVariable.PayFrequency]}
          />
        </div>
        <Button disabled={!isValid} onClick={handleContinue}>
          Next
        </Button>
      </FormContainer>
    </div>
  );
};

export default YourIncome;
