import React from 'react';

import { ReactComponent as RoadSign } from 'images/road-sign.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './ErrorConsolidating.module.scss';

const ErrorConsolidating = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <div>
    <FormNavigation {...navigationInfo} />
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <RoadSign />
      </div>
      <div className={styles.title}>Sorry, we’re unable to save you money at this time.</div>
      <div className={styles.description}>Let’s continue setting up your card.</div>
      <Button type={ButtonType.Primary} onClick={handleNext}>
        Continue
      </Button>
    </div>
  </div>
);

export default ErrorConsolidating;
