import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserPayrollData } from 'thunks';

import { GetUserDataResponse } from 'api/UserDataApi';

import { AboutYouResponse } from './yourName';
import { YourDetailsResponse } from './yourAddress';
import { YourIncomeResponse } from './yourTotalIncome';
import { CreditReportArgyleResponse } from './creditReport';

interface UserPayrollDataState {
  isLinkSupported: boolean;
  declineReason: string;
  aboutYou: AboutYouResponse;
  yourDetails: YourDetailsResponse;
  yourIncome: YourIncomeResponse;
  creditReport: CreditReportArgyleResponse;
  employmentsDataForLos: Array<Record<string, string | number | undefined>>;
}

interface InitialStateInterface {
  employers: Array<UserPayrollDataState>;
}

const initialState: InitialStateInterface = {
  employers: [],
};

const userPayrollDataGet = createSlice({
  name: 'userPayrollData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }: PayloadAction<GetUserDataResponse>) => {
      analytics.track(`${payload.provider} data received`);
      analytics.identify({
        income: payload.yourIncome?.employmentIncome,
        zipCode: payload.yourDetails?.zip_code,
        state: payload.yourDetails?.argyle_state,
        city: payload.yourDetails?.city,
        isLinkSupported: payload?.isLinkSupported,
      });

      state.employers.push(payload);
      return state;
    });
  },
});

export default userPayrollDataGet.reducer;
