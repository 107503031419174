import { useEffect, useState } from 'react';

const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  const resizeScreen = () => setIsMobile(window.innerWidth < 768);

  useEffect(() => {
    window.addEventListener('resize', resizeScreen);

    return () => {
      window.removeEventListener('resize', resizeScreen);
    };
  }, []);

  return {
    isMobile,
  };
};

export default useScreenSize;
