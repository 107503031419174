import React from 'react';
import clsx from 'clsx';

import styles from './CheckboxSmall.module.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
}

const CheckboxSmall = ({ className, label, onChange, checked, disabled = false, ...rest }: CheckboxProps) => (
  <div className={className}>
    <label className={styles.container}>
      <div className={styles.checkboxLabel}>{label}</div>
      <input type="checkbox" {...rest} onChange={onChange} checked={checked} disabled={disabled} />
      <span className={clsx(styles.checkmark, styles.checkboxCheckmark, disabled && styles.disabled)} />
    </label>
  </div>
);

export default CheckboxSmall;
