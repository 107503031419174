import React, { createContext, FC, useContext, useEffect, useMemo, useReducer } from 'react';

interface UseScriptOptions {
  id?: string;
  onError?: () => void;
}

interface ScriptState {
  loaded: boolean;
  error: boolean;
}

type RegisteredScripts = Record<string, ScriptState>;

enum ActionTypes {
  SetScriptState = 'SetScriptState',
}

interface SetScriptStateAction {
  type: ActionTypes.SetScriptState;
  payload: {
    src: string;
  } & ScriptState;
}

interface Context {
  scripts: RegisteredScripts;
  setScriptState: (src: string, state: ScriptState) => void;
}

const ScriptsContext = createContext<Context>({
  scripts: {},
  setScriptState: () => {},
});

const reducer = (state: RegisteredScripts, action: SetScriptStateAction) => {
  const { src, ...scriptState } = action.payload;
  return {
    ...state,
    [src]: scriptState,
  };
};

const ScriptsLoader: FC = ({ children }) => {
  const [scripts, dispatch] = useReducer(reducer, {});

  const setScriptState = (src: string, state: ScriptState) => {
    dispatch({
      type: ActionTypes.SetScriptState,
      payload: {
        src,
        ...state,
      },
    });
  };

  const contextValue = useMemo(() => ({ scripts, setScriptState }), [scripts]);

  return <ScriptsContext.Provider value={contextValue}>{children}</ScriptsContext.Provider>;
};

const useScript = (src: string, options: UseScriptOptions = {}) => {
  const { scripts, setScriptState } = useContext(ScriptsContext);

  useEffect(() => {
    if (scripts[src]) {
      return;
    }

    setScriptState(src, {
      loaded: false,
      error: false,
    });

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    if (options.id) {
      script.id = options.id;
    }

    const onScriptLoad = () => {
      setScriptState(src, {
        loaded: true,
        error: false,
      });
    };

    const onScriptError = () => {
      setScriptState(src, {
        loaded: true,
        error: true,
      });

      options.onError?.();
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);
  }, [src]);

  return scripts[src] || {};
};

export { ScriptsLoader, useScript };
