import Api, { FetchMethodType } from './Api';

export interface DataForDeclineNotification {
  email: string;
  phoneNumber: string;
  borrowerState: string;
  loanAmountRange: string;
  creditScoreRange: string;
  declineReason: string;
}

export interface PreQualificationDeclineApi {
  sendNotificationEmail(data: DataForDeclineNotification): Promise<void>;
}

export default class PreQualificationDeclineRestApi extends Api implements PreQualificationDeclineApi {
  public async sendNotificationEmail(data: DataForDeclineNotification) {
    await this.fetch<void>('/emailNotification', {
      method: FetchMethodType.POST,
      body: {
        data,
      },
    });
  }
}
