import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailApi } from 'api/EmailApi';

export const createSendEnrollmentFormEmail = (api: EmailApi) =>
  createAsyncThunk('payrollDeduction/sendEnrollmentFormEmail', async (applicationId: string, thunkApi) => {
    try {
      await api.sendDirectDepositEnrollmentFormEmail(applicationId);
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
