import React from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { FlowComponentType } from 'routes/FlowRouter';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { addDays, formatTextDate } from 'utils/dateUtils';
import Contact from 'components/Contact';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { DEFAULT_LATE_FEE, LATE_FEE_DAYS } from 'components/MissedPayments/constants';

import styles from './MakeAPayment.module.scss';

const MakeAPayment = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const navigate = useNavigate();
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { missedPaymentAmount, missedPaymentDate, lateFee } = application;

  const date = new Date(missedPaymentDate!);

  return (
    <div>
      <FormNavigation {...navigationInfo} onBackClick={() => navigate(RoutePath.MissedPayment)} />
      <FormContainer title="Make a Payment">
        <p className={styles.text}>
          Make a payment of {formatMonetaryAmount(missedPaymentAmount, true)} before{' '}
          {formatTextDate(addDays(date, LATE_FEE_DAYS))} to avoid a {formatMonetaryAmount(lateFee || DEFAULT_LATE_FEE)}{' '}
          late fee.
        </p>
        <p className={styles.text}>
          Staying current on your loan allows us to continue helping other healthcare professionals.
        </p>

        <Button onClick={handleNext}>Make a Payment</Button>
      </FormContainer>
      <h2 className={styles.contactTitle}>We're Here to Help!</h2>
      <p className={styles.contactSubtitle}>If you’re in financial distress, we can discuss other payment options.</p>
      <Contact className={styles.contact} showBackButton={false} />
    </div>
  );
};

export default MakeAPayment;
