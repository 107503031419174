import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { FlowComponentType } from 'routes/FlowRouter';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { RootState } from 'handlers';
import { createDocuSignEnvelope } from 'thunks';
import { DocuSignDocument } from 'api/DocuSignEnvelopeApi';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ReactComponent as SignIcon } from 'images/sign-icon.svg';

import styles from './Authorization.module.scss';

const Authorization = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { hasSignedACHForms, bankName } = application;

  const { isLoading } = useSelector((state: RootState) => state.docuSignEnvelope);

  useEffect(() => {
    if (hasSignedACHForms) {
      handleNext();
    }
  }, []);

  const handleCreateDocuSignEnvelopment = async () => {
    if (application) {
      const applicationId = application.id;
      window.location.replace(
        await dispatchWithUnwrap(
          createDocuSignEnvelope({
            applicationId,
            windowLocationHref: window.location.href,
            document: DocuSignDocument.ACH,
          }),
        ),
      );
    }
  };

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Authorization" className={styles.container}>
        <SignIcon />

        <p className={styles.text}>
          We need to receive your authorization before we can transfer money from your {bankName} account.
        </p>
        <p className={styles.text}>Click "Sign Authorization" below to be redirected to DocuSign.</p>

        <Button onClick={handleCreateDocuSignEnvelopment} isLoading={isLoading}>
          Sign Authorization
        </Button>
      </FormContainer>
    </div>
  );
};

export default Authorization;
