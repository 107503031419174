import React from 'react';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { FlowComponentType } from 'routes/FlowRouter';
import useVerificationSteps from 'components/Verification/useVerificationSteps';

import FirstTimeFlow from './FirstTimeFlow';
import ReturningFlow from './ReturningFlow';

import styles from './SetupAccount.module.scss';

const SetupAccount = ({ handleNext }: FlowComponentType) => {
  const { verificationStarted } = useVerificationSteps();

  const subtitle = verificationStarted ? (
    <p>Just a few steps left until you're officially part of the Plannery community!</p>
  ) : (
    <span className={styles.subtitle}>
      <p>We need a few things from you so we can verify your identity and employment status.</p>
      <p>This&nbsp;should&nbsp;take&nbsp;5&nbsp;minutes. We’ll&nbsp;save&nbsp;your&nbsp;progress.</p>
    </span>
  );

  return (
    <div className={styles.container}>
      <FormContainer
        icon={verificationStarted ? undefined : <CheckIcon className={styles.checkIcon} />}
        title="Finish setting up your account"
        subtitle={subtitle}
      >
        <div className={styles.flowContent}>{verificationStarted ? <ReturningFlow /> : <FirstTimeFlow />}</div>
        <Button className={styles.button} onClick={handleNext}>
          Next
        </Button>
      </FormContainer>
    </div>
  );
};

export default SetupAccount;
