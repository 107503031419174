export interface SurveyQuestion {
  question: string;
  options: SurveyOption[];
  type: QuestionType;
}

export interface SurveyOption {
  value: string;
  type?: QuestionType;
}

export enum QuestionType {
  Main = 'Main',
  Security = 'Security',
  Device = 'Device',
  Application = 'Application',
}

export interface Answer {
  [key: string]: string[] | undefined;
}

export const mainSurveyQuestions: SurveyQuestion = {
  type: QuestionType.Main,
  question: 'Choose all that apply:',
  options: [
    { value: "I am not sure if I'll be approved.", type: QuestionType.Application },
    { value: "I don't have the necessary information to complete the application.", type: QuestionType.Application },
    { value: "I'm not sure my data will be secure online.", type: QuestionType.Security },
    { value: 'I prefer to open an account over the phone.', type: QuestionType.Device },
    { value: 'I have questions about the application and need assistance.', type: QuestionType.Application },
    { value: 'I was just browsing and never intended to submit an application.' },
    { value: 'I want to open the account on a different device.', type: QuestionType.Device },
    { value: 'I experienced technical difficulties applying.' },
    {
      value: 'I am not sure if I am applying for the correct offer or best promotional offer.',
      type: QuestionType.Application,
    },
    { value: 'Other' },
  ],
};

export const subsequentQuestions: SurveyQuestion[] = [
  {
    type: QuestionType.Security,
    question: 'Which of the following best describes your concerns with security?',
    options: [
      { value: "I don't want to provide my name." },
      { value: "I don't want to provide my phone number." },
      { value: "I don't want to provide my email address." },
      { value: "I don't want Plannery to access my credit report data." },
      { value: 'Other' },
    ],
  },
  {
    type: QuestionType.Device,
    question: 'Which of the following best describes your reasons for opening the account on a different device?',
    options: [
      { value: 'I want to open this account on a computer.' },
      { value: 'I want to open this account on a tablet.' },
      { value: 'I want to open this account on a mobile phone.' },
      { value: 'Other' },
    ],
  },
  {
    type: QuestionType.Application,
    question: 'Which of the following best describes your question about the application?',
    options: [
      { value: 'Promotional offer terms are unclear.' },
      { value: 'The pricing terms and fees are unclear.' },
      { value: "I'm not sure how to answer the questions in the application." },
      { value: 'Other' },
    ],
  },
];
