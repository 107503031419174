import EmployersApi, { Employer, EmployersSearchParams } from 'api/EmployersApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'handlers';
import { getSupportedPayrollProviders } from 'utils/SupportedPayrollProviders';

type Params = Omit<EmployersSearchParams, 'providers'>;

const createSearchEmployersThunk = (api: EmployersApi) =>
  createAsyncThunk<Employer[], Params, { state: RootState }>(
    'searchEmployers',
    async (params: Params, { getState }) => {
      const providers = getSupportedPayrollProviders(getState());

      const employers = await api.searchEmployers({
        ...params,
        providers,
      });

      return employers;
    },
  );

export default createSearchEmployersThunk;
