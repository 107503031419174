import React, { FC } from 'react';
import clsx from 'clsx';
import Loader from 'components/Loader';

import styles from './Anchor.module.scss';

interface AnchorProps {
  isLoading: boolean;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}

const Anchor: FC<AnchorProps> = ({ children, className, onClick, isLoading = false, disabled }) => (
  <div className={clsx(styles.container, className)}>
    {isLoading ? (
      <div className={styles.loaderContainer}>
        <Loader size={40} />
      </div>
    ) : (
      <div
        onClick={() => !disabled && onClick()}
        className={clsx(styles.anchor, {
          [styles.disabled]: disabled,
        })}
      >
        {children}
      </div>
    )}
  </div>
);

export default Anchor;
