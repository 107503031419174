import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YourContactVariable } from 'enums/LoanFormVariables';
import { ConversionType, trackConversion } from 'utils/analytics';

export interface YourContactData {
  [YourContactVariable.Email]: string;
  [YourContactVariable.PhoneNumber]: string;
}

export const initialState: YourContactData = {
  [YourContactVariable.Email]: '',
  [YourContactVariable.PhoneNumber]: '',
};

const yourContact = createSlice({
  name: 'yourContact',
  initialState,
  reducers: {
    setYourContactData: (state: YourContactData, { payload }: PayloadAction<Partial<YourContactData>>) => {
      const email = payload[YourContactVariable.Email];
      if (email) {
        analytics.identify({ email });
        if (!state[YourContactVariable.Email]) {
          trackConversion(ConversionType.CollectedEmail);
        }

        state[YourContactVariable.Email] = email;
      }

      const phoneNumber = payload[YourContactVariable.PhoneNumber];
      if (phoneNumber) {
        analytics.identify({ phoneNumber });
        if (!state[YourContactVariable.PhoneNumber]) {
          trackConversion(ConversionType.CollectedPhone);
        }

        state[YourContactVariable.PhoneNumber] = phoneNumber;
      }
    },
  },
});

export const { setYourContactData } = yourContact.actions;

export default yourContact.reducer;
