import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendNotification } from 'thunks';

interface PreQualificationDecline {
  email: string | undefined;
  phoneNumber: string | undefined;
  isSending: boolean;
}

const initialState: PreQualificationDecline = {
  email: undefined,
  phoneNumber: undefined,
  isSending: false,
};

const preQualificationDecline = createSlice({
  name: 'preQualificationDecline',
  initialState,
  reducers: {
    setNotificationData: (
      state: PreQualificationDecline,
      { payload }: PayloadAction<{ email: string; phoneNumber: string }>,
    ) => {
      state.email = payload.email;
      state.phoneNumber = payload.phoneNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendNotification.pending, (state) => {
      state.isSending = true;
    });
    builder.addCase(sendNotification.fulfilled, (state) => {
      state.isSending = false;
    });
    builder.addCase(sendNotification.rejected, (state) => {
      state.isSending = false;
    });
  },
});

export const { setNotificationData } = preQualificationDecline.actions;

export default preQualificationDecline.reducer;
