export enum PartnerBrandingName {
  Flasha = 'flasha',
  Fpma = 'fpma',
}

export const getPartnerName = (partner: PartnerBrandingName) => {
  const index = Object.values(PartnerBrandingName).indexOf(partner);
  if (index === -1) {
    return partner
      .split('-')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
      .join(' ');
  }
  const key = Object.keys(PartnerBrandingName)[index];
  return key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3');
};
