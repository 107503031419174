import React from 'react';

const ErrorMessages = {
  Default: (
    <span>
      Something went wrong. If this error continues, please{' '}
      <a className="anchor" href="mailto:support@planneryapp.com">
        contact support
      </a>
      .
    </span>
  ),
};

export default ErrorMessages;
