import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { RootState } from 'handlers';
import { setNotificationData } from 'handlers/preQualificationDecline';
import { getMessageForInvalidFields } from 'utils/errors';
import { STATE_OPTIONS } from 'utils/getCountryStateLabel';
import BackLink from 'components/BackLink';
import Button from 'components/Button';
import Input from 'components/Input';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { sendNotification } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { EMAIL_PATTERN } from 'components/LoanForm/YourContact/YourContact';

import styles from './PreQualificationDecline.module.scss';

const PHONE_NUMBER_LENGTH = 14;

enum NotificationType {
  Email = 'Email',
  PhoneNumber = 'Phone Number',
}

const PreQualificationDecline = () => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();

  const {
    borrower_state_or_province: countyState,
    loan_amount_range: loanAmountRange,
    credit_score_range: creditScoreRange,
  } = useSelector((state: RootState) => state.preQualificationData);
  const { decline_reasons: declineReason } = useSelector(
    (state: RootState) => state.preQualificationResult.preQualificationResponse!,
  );

  analytics.track('Prequalification Decline Shown', { declineReason });

  const { email, phoneNumber, isSending } = useSelector((state: RootState) => state.preQualificationDecline);

  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(true);

  const defaultValues = {
    [NotificationType.Email]: email,
    [NotificationType.PhoneNumber]: phoneNumber,
  };

  const {
    register,
    watch,
    formState: { errors, isValid },
    trigger,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watcher = watch();

  const isActive =
    (watcher[NotificationType.Email] && isValid) || (watcher[NotificationType.PhoneNumber] && phoneNumberIsValid);

  const handleNotifyMe = async () => {
    analytics.track('Notify Me Clicked', {
      emailSubmitted: Boolean(email),
      phoneNumberSubmitted: Boolean(phoneNumber),
    });
    await dispatch(
      setNotificationData({
        email: watcher[NotificationType.Email]!,
        phoneNumber: watcher[NotificationType.PhoneNumber]!,
      }),
    );

    await dispatchWithUnwrap(
      sendNotification({
        email: watcher[NotificationType.Email]!,
        phoneNumber: watcher[NotificationType.PhoneNumber]!,
        borrowerState: STATE_OPTIONS.find((state) => countyState! === state.value)?.label!,
        loanAmountRange: loanAmountRange!,
        creditScoreRange: creditScoreRange!,
        declineReason: declineReason!,
      }),
    );

    navigate(RoutePath.EmailNotificationAlert, { state: { hasSeenDecline: true } });
  };

  const onEmailChange = (value: string) => {
    trigger(value as NotificationType);
  };

  const onPhoneNumberChange = (value: string, isValidNumber: boolean) => {
    if (!isValidNumber && value) {
      return setError(NotificationType.PhoneNumber, {
        message: getMessageForInvalidFields(NotificationType.PhoneNumber),
      });
    }
    setError(NotificationType.PhoneNumber, {});
    setPhoneNumberIsValid(true);

    trigger(value as NotificationType);
  };

  useEffect(() => {
    register(NotificationType.Email, {
      pattern: {
        message: getMessageForInvalidFields(NotificationType.Email),
        value: EMAIL_PATTERN,
      },
    });
    register(NotificationType.PhoneNumber, {});
  }, [register, watcher]);

  return (
    <>
      <BackLink className={styles.backLink} />
      <div className={styles.locationError}>
        <div>
          <h1 className={styles.title}>How can we contact you about your loan offer?</h1>
          <Input
            label="Email"
            placeholder="example@email.com"
            errorMessage={errors[NotificationType.Email]?.message}
            className={styles.emailInput}
            name={NotificationType.Email}
            onChange={(event) => {
              setValue(NotificationType.Email, event.target.value.trim());
              if (!event.target.value) {
                clearErrors(NotificationType.Email);
              } else {
                onEmailChange(event.target.name);
              }
            }}
            value={watcher[NotificationType.Email]}
          />
          <PhoneNumberInput
            label="Phone Number"
            placeholder="(XXX) XXX-XXXX"
            errorMessage={errors[NotificationType.PhoneNumber]?.message}
            className={styles.phoneInput}
            name={NotificationType.PhoneNumber}
            onChange={(value) => {
              if (value) {
                setPhoneNumberIsValid(isValidPhoneNumber(value as string));
                onPhoneNumberChange(value as string, isValidPhoneNumber(value as string));
              } else {
                clearErrors(NotificationType.PhoneNumber);
              }
              setValue(NotificationType.PhoneNumber, value);
            }}
            value={watcher[NotificationType.PhoneNumber]}
            country="US"
            maxLength={PHONE_NUMBER_LENGTH}
          />
        </div>
        <Button disabled={!isActive} className={styles.button} onClick={handleNotifyMe} isLoading={isSending}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default PreQualificationDecline;
