import React, { FC } from 'react';

import ProgressBar from 'components/ProgressBar';

import styles from './StateContainer.module.scss';

export interface ContainerProps {
  title: String;
  icon: JSX.Element;
  progress?: number;
}

const StateContainer: FC<ContainerProps> = ({ title, icon, progress }) => (
  <div className={styles.stateContainer}>
    {React.cloneElement(icon, { className: progress !== undefined ? styles.iconLarge : styles.icon })}

    {progress !== undefined && <ProgressBar progress={progress} />}

    <div className={styles.title}>{title}</div>
  </div>
);

export default StateContainer;
