import { CurrentFlow } from 'enums/CurrentFlow';
import { ThemeConfig } from 'hooks/useLayoutTheme';
import { cardRoutes } from 'styles/themes/themeUtils';
import { Themes } from 'styles/themes/ThemeTypes';

import styles from './theme.module.scss';

const config: ThemeConfig = {
  name: Themes.PURPLE,
  routes: cardRoutes,
  flows: [CurrentFlow.Card],
  styles,
};

export default config;
