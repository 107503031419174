import React, { useEffect } from 'react';
import BackLink from 'components/BackLink';
import { RoutePath } from 'enums/Routes';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useNavigate } from 'hooks/useNavigate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FormContainer from 'components/LoanForm/FormContainer';
import { useSelector } from 'react-redux';
import { getPaymentAccount } from 'thunks';
import { getApplicationData } from 'selectors/getApplicationData';
import { getPaymentAccountData } from 'selectors/paymentAccount';

import SetupMethodCard from './components/SetupMethodCard';

import styles from './ManualDeduction.module.scss';

export enum ManualDeductionMethod {
  ViaPayroll = 'viaPayroll',
  ViaHr = 'viaHr',
}

const ManualDeduction = () => {
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { enableTreasuryPrimeIntegration } = useFlags();

  const { bankAccountData } = useSelector(getPaymentAccountData);
  const applicationId = useSelector(getApplicationData).application?.id;

  useEffect(() => {
    (async () => {
      if (applicationId && !bankAccountData) {
        await dispatchWithUnwrap(getPaymentAccount({ applicationId }));
      }
    })();
  }, [bankAccountData]);

  const handleContinue = (manualDeductionMethod: ManualDeductionMethod) => {
    if (manualDeductionMethod === ManualDeductionMethod.ViaPayroll) {
      navigate(RoutePath.SetupViaPayroll);
    } else {
      navigate(RoutePath.SetupViaHr);
    }
  };

  return (
    <>
      {enableTreasuryPrimeIntegration && <BackLink />}
      <FormContainer
        title="Let's setup your payroll deduction."
        subtitle="Select an option below to initiate your payroll deduction"
      >
        <div className={styles.cardsContainer}>
          <SetupMethodCard
            onClick={() => handleContinue(ManualDeductionMethod.ViaPayroll)}
            pillLabel="Fastest Method"
            label="Setup by logging into your payroll system"
            buttonLabel="Setup via payroll"
          />
          <SetupMethodCard
            onClick={() => handleContinue(ManualDeductionMethod.ViaHr)}
            label="Setup by contacting your HR manager"
            buttonLabel="Setup via HR"
          />
        </div>
      </FormContainer>
    </>
  );
};

export default ManualDeduction;
