import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi } from 'api/ApplicationDataApi';
import { GetApplicationDataResponse } from 'handlers/applicationData';

export const createGetApplicationDataThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/getApplicationData', async (applicationId: string, thunkApi) => {
    try {
      const applicationData: GetApplicationDataResponse = await api.getApplicationData(applicationId);

      return applicationData;
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
