import { createAsyncThunk } from '@reduxjs/toolkit';
import { PreQualificationApi } from 'api/PreQualificationApi';
import { PreQualificationData } from 'handlers/preQualificationData';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';

export const createGetCreditScoreRangeDataThunk = (api: PreQualificationApi) =>
  createAsyncThunk('preQualification/getPreQualification', async (data: PreQualificationData, thunkApi) => {
    try {
      const preQualificationResult = await api.getCreditScoreResult(data);

      localStorage.setItem(LocalStorageKeyName.PreQualificationResult, JSON.stringify(preQualificationResult));

      return preQualificationResult;
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
