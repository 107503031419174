import React from 'react';

import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './CardApplicationSuccessful.module.scss';

const CardApplicationSuccessful = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <div>
    <FormNavigation {...navigationInfo} />
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <CheckIcon />
      </div>
      <div className={styles.title}>Thanks for applying!</div>
      <div className={styles.description}>
        Let's get the card in your hands so you can enjoy cash back and exclusive benefits.
      </div>
      <Button type={ButtonType.Primary} onClick={handleNext}>
        Continue
      </Button>
    </div>
  </div>
);

export default CardApplicationSuccessful;
