import React, { FC, ReactFragment, useState } from 'react';

import { ReactComponent as SelectArrow } from 'images/select-arrow.svg';

import styles from './Expander.module.scss';

interface ExpanderProps {
  label: string | ReactFragment;
  content: string | ReactFragment;
  container?: string;
  selectArrowClassname?: string;
  labelClassname?: string;
  openClassname?: string;
  useEllipse?: boolean;
}

const Expander: FC<ExpanderProps> = ({
  label,
  content,
  container,
  selectArrowClassname,
  labelClassname,
  openClassname,
  useEllipse = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const classes = [styles.expander];
  if (container) {
    classes.push(container);
  }

  return (
    <div className={classes.join(' ')}>
      <div className={labelClassname} onClick={toggle}>
        {label}
        <div className={useEllipse ? styles.ellipse : styles.arrowContainer}>
          <SelectArrow
            className={`${styles.selectArrow} ${isOpen ? styles.selectArrowOpen : ''} ${selectArrowClassname || ''}`}
          />
        </div>
      </div>

      <div className={`${styles.content} ${isOpen ? styles.contentOpen : ''} ${isOpen ? openClassname || '' : ''}`}>
        {content}
      </div>
    </div>
  );
};

export default Expander;
