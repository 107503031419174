import React, { FC } from 'react';
import clsx from 'clsx';
import { Message } from 'react-hook-form';

import InputError from 'components/InputError';

import styles from './TextArea.module.scss';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  errorMessage?: Message;
}

const TextArea: FC<TextAreaProps> = ({ value, className, label, errorMessage, ...restProps }) => (
  <div className={clsx(styles.textAreaContainer, className)}>
    {label && <p className={styles.label}>{label}</p>}
    <textarea
      value={value || ''}
      autoComplete="off"
      className={clsx(styles.textArea, !!errorMessage && styles.error)}
      {...restProps}
    />
    {!!errorMessage && <InputError errorMessage={errorMessage} />}
  </div>
);

export default TextArea;
