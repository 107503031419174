import React from 'react';
import clsx from 'clsx';
import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getYourNameData } from 'selectors/yourName';
import { getFullName } from 'handlers/yourName';

import { ReactComponent as GoldCard } from 'images/cards/gold-card-front.svg';
import { ReactComponent as SilverCard } from 'images/cards/light-purple-card-front.svg';
import { ReactComponent as PurpleCard } from 'images/cards/purple-silver-card-front.svg';

import styles from './CardPreview.module.scss';

const cards = {
  [CardColor.Gold]: {
    card: <GoldCard />,
    cardNameStyle: styles.gold,
  },
  [CardColor.LightPurple]: {
    card: <PurpleCard />,
    cardNameStyle: styles.purple,
  },
  [CardColor.PurpleSilver]: {
    card: <SilverCard />,
    cardNameStyle: styles.silver,
  },
};

interface CardPreviewProps {
  cardColor?: CardColor;
  showName?: boolean;
}

const CardPreview = ({ showName, cardColor = CardColor.Gold }: CardPreviewProps) => {
  const { borrowerCredentials: credentials } = useSelector(getCardData);
  const { application } = useSelector(getApplicationData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const { borrowerFirstName, borrowerLastName } = application ?? {};

  const cardText = `${getFullName(
    firstName || borrowerFirstName || '',
    lastName || borrowerLastName || '',
  )}, ${credentials}`;
  const { card, cardNameStyle } = cards[cardColor as keyof typeof cards];

  return (
    <div className={styles.cardPreviewContainer}>
      <div className={styles.cardPreview}>{card}</div>
      {showName && <p className={clsx(styles.cardText, cardNameStyle)}>{cardText}</p>}
    </div>
  );
};

export default CardPreview;
