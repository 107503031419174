import { createSlice } from '@reduxjs/toolkit';
import { getUserPayrollData } from 'thunks';
import {
  AboutYouVariable,
  CreditReportVariable,
  YourBirthDateVariable,
  YourContactVariable,
  YourDetailsVariable,
} from 'enums/LoanFormVariables';

interface UserDataState {
  isFirstName: boolean;
  isLastName: boolean;
  isDateOfBirth: boolean;
  isEmail: boolean;
  isPhoneNumber: boolean;
  isSsn: boolean;
  isAnnualIncome1: boolean;
  isAnnualIncome2: boolean;
  isPayFrequency1: boolean;
  isPayFrequency2: boolean;
  isFullAddressSent: boolean;
}

export const initialState: UserDataState = {
  isFirstName: false,
  isLastName: false,
  isDateOfBirth: false,
  isEmail: false,
  isPhoneNumber: false,
  isSsn: false,
  isAnnualIncome1: false,
  isAnnualIncome2: false,
  isPayFrequency1: false,
  isPayFrequency2: false,
  isFullAddressSent: false,
};

const userPayrollData = createSlice({
  name: 'userPayrollData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }: any) => {
      if (payload.isLinkSupported) {
        state.isFirstName = !!payload.aboutYou[AboutYouVariable.FirstName];
        state.isLastName = !!payload.aboutYou[AboutYouVariable.LastName];
        state.isDateOfBirth = !!payload.aboutYou[YourBirthDateVariable.DateOfBirth];
        state.isEmail = !!payload.yourDetails[YourContactVariable.Email];
        state.isPhoneNumber = !!payload.yourDetails[YourContactVariable.PhoneNumber];
        state.isSsn = !!payload.creditReport[CreditReportVariable.SocialSecurityNumber];
        state.isFullAddressSent =
          !!payload.yourDetails[YourDetailsVariable.AddressLine1] &&
          !!payload.yourDetails[YourDetailsVariable.City] &&
          !!payload.yourDetails[YourDetailsVariable.ZipCode] &&
          !!payload.yourDetails[YourDetailsVariable.ArgyleState];
      }
    });
  },
});

export default userPayrollData.reducer;
