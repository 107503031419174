import { PayrollProviderName } from 'api/EmployersApi';

import ArgyleWidget from './argyle/ArgyleWidget';
import PinwheelWidget from './pinwheel/PinwheelWidget';

const formatProviderName = (name: string) => name[0].toLocaleUpperCase() + name.slice(1).toLowerCase();

export const getPayrollWidget = (provider: string) => {
  provider = formatProviderName(provider);

  switch (provider) {
    case PayrollProviderName.Argyle:
    case PayrollProviderName.Manual:
      return ArgyleWidget;

    case PayrollProviderName.Pinwheel:
      return PinwheelWidget;

    default:
      throw Error(`getPayrollWidget: Unknown payroll provider: ${provider}`);
  }
};
