import React, { FC } from 'react';
import clsx from 'clsx';
import Button, { ButtonType } from 'components/Button/Button';
import { ReactComponent as ChatIcon } from 'images/chat-icon.svg';
import { ReactComponent as EmailIcon } from 'images/email-icon.svg';

import styles from './Contact.module.scss';

interface ContactProps {
  handleGetBack?: () => void;
  showBackButton?: boolean;
  className?: string;
}

const Contact: FC<ContactProps> = ({ handleGetBack, showBackButton = true, className }) => (
  <>
    <div className={clsx(styles.contactsContainer, className)}>
      <a href="sms:(404) 205-8882" className={styles.contactItemContainer}>
        <ChatIcon />
        <p className={styles.contactsTitle}>Text or call us</p>
        <p className={styles.contactsSubtitle}>(404) 205-8882</p>
      </a>
      <a href="mailto:outofstate@planneryapp.com" className={styles.contactItemContainer}>
        <EmailIcon />
        <p className={styles.contactsTitle}>Email us</p>
        <p className={styles.contactsSubtitle}>Typically respond within 24-48 hours</p>
      </a>
    </div>
    {showBackButton && (
      <Button type={ButtonType.Secondary} onClick={handleGetBack} className={styles.button}>
        Back to Plannery homepage
      </Button>
    )}
  </>
);

export default Contact;
