import React from 'react';

import styles from './ComparisonTable.module.scss';

interface ComparisonTableProps {
  comparisonLabel: string;
  leftTable: ComparisonColumn;
  rightTable: ComparisonColumn;
}

interface ComparisonColumn {
  title: string;
  rows: ComparisonRow[];
}

interface ComparisonRow {
  amount: number | string;
  label: string;
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({ comparisonLabel, leftTable, rightTable }) => (
  <div className={styles.container}>
    <span className={styles.comparisonLabel}>{comparisonLabel}</span>

    <div className={styles.tableContainer}>
      <div className={styles.tableColumn}>
        <p className={styles.title}>{leftTable.title}</p>
        {leftTable.rows.map((row) => (
          <ComparisonTableRow {...row} key={row.label.trim()} />
        ))}
      </div>
      <div className={styles.tableColumn}>
        <p className={styles.title}>{rightTable.title}</p>
        {rightTable.rows.map((row) => (
          <ComparisonTableRow {...row} key={row.label.trim()} />
        ))}
      </div>
    </div>
  </div>
);

const ComparisonTableRow: React.FC<ComparisonRow> = ({ amount, label }) => (
  <div className={styles.rowContainer}>
    <p className={styles.amount}>{amount}</p>
    <p className={styles.label}>{label}</p>
  </div>
);

export default ComparisonTable;
