import React from 'react';

import { ReactComponent as CloseIcon } from 'images/cross-icon.svg';

import styles from './Modal.module.scss';

interface ModalProps {
  children: JSX.Element;
  closeModal: () => void;
}

const Modal = ({ children, closeModal }: ModalProps) => (
  <>
    <div className={styles.container}>
      <CloseIcon onClick={closeModal} className={styles.closeIcon} />
      {children}
    </div>

    <div className={styles.backdrop} />
  </>
);

export default Modal;
