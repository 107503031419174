import { toast, ToastOptions } from 'react-toastify';

const TOAST_CLOSE_DELAY = 5000;

const defaultConfig = {
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: TOAST_CLOSE_DELAY,
  closeButton: false,
  hideProgressBar: true,
  icon: false,
};

export const failureNotification = (message: string | JSX.Element, config?: ToastOptions) => {
  const options = config ? { ...defaultConfig, ...config } : defaultConfig;
  toast.error(message, options);
};

export const successNotification = (message: string | JSX.Element, config?: ToastOptions) => {
  const options = config ? { ...defaultConfig, ...config } : defaultConfig;
  toast.success(message, options);
};
