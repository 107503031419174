import React, { useEffect, useRef, useState } from 'react';

import Loader from 'components/Loader';
import { ProfessionGroup } from 'enums/ProfessionGroup';

import styles from './CardModel.module.scss';

const CARD_WIDTH = 293;
const CARD_HEIGHT = 184;
const CANVAS_SCALE = 4;

export enum Color {
  BlackGold = 'Black_Gold',
  DarkPurpleSilver = 'DarkPurple_Silver',
  PurpleWhite = 'Purple_White',
}

const TEXT_COLORS: Record<Color, string> = {
  [Color.BlackGold]: '#E0CA91',
  [Color.DarkPurpleSilver]: '#D8D8D8',
  [Color.PurpleWhite]: '#FFFFFF',
};

const EDGE_COLORS: Record<Color, string> = {
  [Color.BlackGold]: '#000000',
  [Color.DarkPurpleSilver]: '#422DB1',
  [Color.PurpleWhite]: '#b8a9f4',
};

enum Orientation {
  Front = '0deg 75deg 105%',
  Back = '180deg 105deg 105%',
}

const FRONT_ORBIT: ModelViewer.Orbit = {
  theta: 0,
  phi: (Math.PI * 5) / 12,
  radius: 3.7594360607037896,
  toString: () => '0deg 75deg 105%',
} as const;
const BACK_ORBIT: ModelViewer.Orbit = {
  theta: Math.PI,
  phi: (Math.PI * 7) / 12,
  radius: 3.7594360607037896,
  toString: () => '180deg 105deg 105%',
} as const;

interface CardModelProps {
  text: string;
  color: Color;
  group: ProfessionGroup;
}

const CardModel = ({ text, color, group }: CardModelProps) => {
  const modelPath = 'card.gltf';

  const modelRef = useRef<ModelViewer.HTMLModelViewerElement>(null);
  const frontImageRef = useRef<HTMLImageElement>(null);
  const backImageRef = useRef<HTMLImageElement>(null);
  const [rotationTimeout, setRotationTimeout] = useState<NodeJS.Timeout>();

  const [frontTexture, setFrontTexture] = useState<ModelViewer.CanvasTexture>();
  const [frontText, setFrontText] = useState('');
  const [frontColor, setFrontColor] = useState(Color.BlackGold);

  const [backTexture, setBackTexture] = useState<ModelViewer.CanvasTexture>();
  const [backText, setBackText] = useState('');
  const [backColor, setBackColor] = useState(Color.BlackGold);

  const drawCardText = (
    context: CanvasRenderingContext2D,
    fontSize: number,
    letterSpacing: number,
    x: number,
    y: number,
  ) => {
    const spaces = [];
    for (let index = 0; index < letterSpacing; index += 1) {
      spaces.push(String.fromCharCode(8202));
    }
    const transformedText = text.toUpperCase().split('').join(spaces.join(''));
    context.font = `${fontSize * CANVAS_SCALE}px/160% Poppins`;
    context.fillStyle = TEXT_COLORS[color];
    context.fillText(transformedText, x * CANVAS_SCALE, (y + fontSize) * CANVAS_SCALE);
  };

  const updateFrontImage = () => {
    const model = modelRef.current?.model;
    if (model && frontImageRef.current && frontImageRef.current.complete) {
      const { baseColorTexture } = model.materials[0].pbrMetallicRoughness;

      const canvasTexture = frontTexture ?? modelRef.current!.createCanvasTexture();
      const canvas: HTMLCanvasElement = canvasTexture.source.element;
      const context = canvas.getContext('2d')!;

      if (!frontTexture || color !== frontColor || !text.trimEnd().startsWith(frontText.trimEnd())) {
        canvas.width = CARD_WIDTH * CANVAS_SCALE;
        canvas.height = CARD_HEIGHT * CANVAS_SCALE;

        context.translate(0, context.canvas.height);
        context.scale(1, -1);

        context.drawImage(frontImageRef.current!, 0, 0, context.canvas.width, context.canvas.height);
      }

      drawCardText(context, 12, 2, 20, 145);

      canvasTexture.source.update();
      baseColorTexture.setTexture(canvasTexture);

      setFrontTexture(canvasTexture);
      setFrontColor(color);
      setFrontText(text);
    }
  };

  const updateBackImage = () => {
    const model = modelRef.current?.model;
    if (model && backImageRef.current && backImageRef.current.complete) {
      const { baseColorTexture } = model.materials[1].pbrMetallicRoughness;

      const canvasTexture = backTexture ?? modelRef.current!.createCanvasTexture();
      const canvas: HTMLCanvasElement = canvasTexture.source.element;
      const context = canvas.getContext('2d')!;

      if (!backTexture || color !== backColor || !text.trimEnd().startsWith(backText.trimEnd())) {
        canvas.width = CARD_WIDTH * CANVAS_SCALE;
        canvas.height = CARD_HEIGHT * CANVAS_SCALE;

        context.translate(context.canvas.width, context.canvas.height);
        context.scale(-1, -1);

        context.drawImage(backImageRef.current!, 0, 0, context.canvas.width, context.canvas.height);
      }

      drawCardText(context, 10, 0, 20, 109);

      canvasTexture.source.update();
      baseColorTexture.setTexture(canvasTexture);

      setBackTexture(canvasTexture);
      setBackColor(color);
      setBackText(text);
    }
  };

  const updateEdge = () => {
    const model = modelRef.current?.model;
    if (model) {
      const { baseColorTexture } = model.materials[2].pbrMetallicRoughness;

      const canvasTexture = modelRef.current!.createCanvasTexture();
      const canvas: HTMLCanvasElement = canvasTexture.source.element;
      canvas.width = 1;
      canvas.height = 1;

      const context = canvas.getContext('2d')!;
      context.fillStyle = EDGE_COLORS[color];
      context.fillRect(0, 0, 1, 1);

      canvasTexture.source.update();
      baseColorTexture.setTexture(canvasTexture);
    }
  };

  const updateImages = () => {
    updateFrontImage();
    updateBackImage();
    updateEdge();
  };

  const onClick = () => {
    if (modelRef.current) {
      const modelViewer = modelRef.current;
      if (Math.abs(modelViewer.getCameraOrbit().theta % Math.PI) < 0.001) {
        snapToSide(modelViewer, Orientation.Back, Orientation.Front);
        analytics.track('Card Flipped');
      } else {
        snapToSide(modelViewer, Orientation.Front, Orientation.Back);
        analytics.track('Card Snapped');
      }
    }
  };

  const [addedLoadEvent, setAddedLoadEvent] = useState(false);
  const [loadTime, setLoadTime] = useState(0);

  useEffect(() => {
    if (modelRef.current) {
      if (!addedLoadEvent) {
        modelRef.current.addEventListener(
          'load',
          () => {
            updateImages();
            modelRef.current!.addEventListener('click', onClick);
            setLoadTime(new Date().getTime());
          },
          { once: true },
        );
        setAddedLoadEvent(true);
      }
      updateImages();
    }
  }, [text, color]);

  useEffect(() => {
    if (modelRef.current && loadTime && new Date().getTime() - loadTime >= 1000) {
      const modelViewer = modelRef.current;
      setRotationTimeout(rotate360(modelViewer, 5000, rotationTimeout));
    }
  }, [color]);

  return (
    <div className={styles.modelViewer}>
      <img
        src={`/card-front-images/Color=${color}, Group=${group}.svg`}
        alt=""
        ref={frontImageRef}
        className={styles.hidden}
        onLoad={updateFrontImage}
      />
      <img
        src={`/card-back-images/Color=${color}.svg`}
        alt=""
        ref={backImageRef}
        className={styles.hidden}
        onLoad={updateBackImage}
      />
      <model-viewer
        src={modelPath}
        ref={modelRef}
        camera-controls
        touch-action="rotate"
        disable-pan
        disable-tap
        orientation={Orientation.Front}
      >
        <Loader color="#ffffff80" slot="poster" className={styles.loader} size={100} />
      </model-viewer>
    </div>
  );
};

export default CardModel;

const linear = (value: number) => value;
const easeIn = (value: number) => value * value;
const easeOut = (value: number) => 1 - (1 - value) * (1 - value);

function interpolate(
  min: number,
  max: number,
  value: number,
  minValue: number = 0,
  maxValue: number = 1,
  ease: (number: number) => number = linear,
) {
  const normalizedValue = ease((value - minValue) / (maxValue - minValue));
  return min + (max - min) * normalizedValue;
}

function interpolateOrbit(
  min: ModelViewer.Orbit,
  max: ModelViewer.Orbit,
  value: number,
  minValue: number = 0,
  maxValue: number = 1,
  ease: (number: number) => number = linear,
): ModelViewer.Orbit {
  const theta = interpolate(min.theta, max.theta, value, minValue, maxValue, ease);
  const phi = interpolate(min.phi, max.phi, value, minValue, maxValue, ease);
  const radius = interpolate(min.radius, max.radius, value, minValue, maxValue);
  return {
    theta,
    phi,
    radius,
    toString: () => `${theta}rad ${phi}rad ${radius}m`,
  };
}

function get360DegreeRotationProgress(progress: number): ModelViewer.Orbit {
  if (progress < 0.5) {
    return interpolateOrbit(FRONT_ORBIT, BACK_ORBIT, progress, 0, 0.5, easeIn);
  }
  if (progress < 1) {
    return interpolateOrbit(
      BACK_ORBIT,
      {
        ...FRONT_ORBIT,
        theta: FRONT_ORBIT.theta + Math.PI * 2,
      },
      progress,
      0.5,
      1,
      easeOut,
    );
  }
  return FRONT_ORBIT;
}

function rotate360(
  modelViewer: ModelViewer.HTMLModelViewerElement,
  duration: number,
  existingTimeout?: NodeJS.Timeout,
): NodeJS.Timeout {
  if (existingTimeout) {
    clearInterval(existingTimeout);
  }
  const rotateStart = new Date().getTime();
  let interval: NodeJS.Timeout;
  const rotate = () => {
    const progress = (new Date().getTime() - rotateStart) / duration;
    const newOrbit = get360DegreeRotationProgress(progress);
    if (progress >= 1) {
      clearInterval(interval);
    }
    modelViewer.cameraOrbit = newOrbit.toString();
  };
  modelViewer.interactionPrompt = 'none';
  interval = setInterval(rotate, 1);
  return interval;
}

function snapToSide(
  modelViewer: ModelViewer.HTMLModelViewerElement,
  orientationIfFront: Orientation,
  orientationIfBack: Orientation,
) {
  modelViewer.interpolationDecay = 200;
  setTimeout(() => {
    modelViewer.interpolationDecay = 50;
  }, 500);

  const theta = Math.abs(modelViewer.getCameraOrbit().theta);

  if (theta < Math.PI / 2 || theta > (3 * Math.PI) / 2) {
    modelViewer.cameraOrbit = orientationIfFront;
  } else {
    modelViewer.cameraOrbit = orientationIfBack;
  }
}
