import { RootState } from 'handlers';
import { PayrollProviderName } from 'api/EmployersApi';

const getSupportedPayrollProviders = (state: RootState): PayrollProviderName[] => {
  const { providers } = state.payrollProviders;

  return providers.filter(({ enabled }) => enabled).map(({ name }) => name);
};

export { getSupportedPayrollProviders };
