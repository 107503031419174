export enum UtmTagName {
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
}

export enum UtmSource {
  Site = 'site',
  Referral = 'referral',
}

export enum UtmCampaign {
  PlanneryCard = 'plannery_card',
}
