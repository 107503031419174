import React, { FC } from 'react';
import useDropdown from 'hooks/useDropdown';
import clsx from 'clsx';
import { ReactComponent as ChevronIcon } from 'images/chevron.svg';
import { ReactComponent as CopyIcon } from 'images/copy-icon.svg';
import { copyToClipboard } from 'utils/copyToClipboard';

import styles from './MoreInformation.module.scss';

enum AccountDataLabels {
  employeeName = 'Employee Name',
  accountType = 'Account Type',
  bankName = 'Bank Name',
}

interface AccountData {
  employeeName: string;
  accountType?: string;
  bankName?: string;
}

const MoreInformation: FC<{ accountData: AccountData }> = ({ accountData }) => {
  const { isOpen, handleToggleDropdown, contentRef } = useDropdown();

  return (
    <div className={styles.container}>
      <div onClick={handleToggleDropdown} className={styles.header}>
        <div className={styles.headerLabel}>More Information</div>
        <div
          className={clsx(styles.dropdownButton, {
            [styles.isToggled]: isOpen,
          })}
        >
          <ChevronIcon />
        </div>
      </div>

      <div
        className={clsx(styles.content, {
          [styles.dropdownOpen]: isOpen,
        })}
        ref={contentRef}
      >
        {Object.keys(accountData).map((item: string) => (
          <div key={item} className={styles.accountItem}>
            <div className={styles.accountItemName}>{AccountDataLabels[item as keyof typeof AccountDataLabels]}</div>
            {accountData[item as keyof AccountData] && (
              <div className={styles.accountItemContainer}>
                <div className={styles.accountItemValue}>{accountData[item as keyof AccountData]}</div>
                <CopyIcon
                  className={styles.copyIcon}
                  onClick={() => copyToClipboard(accountData[item as keyof AccountData]!)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoreInformation;
