import React from 'react';
import clsx from 'clsx';

import { ReactComponent as RoundedCheck } from 'images/rounded-check.svg';

import styles from './CheckingAccountRow.module.scss';

interface CheckboxProps {
  selected: boolean;
}

const RoundedCheckBox = ({ selected }: CheckboxProps) => (
  <div className={styles.checkbox}>
    <div
      className={clsx(styles.checkbox__inner, {
        [styles.selected]: selected,
      })}
    >
      <RoundedCheck />
    </div>
  </div>
);

export default RoundedCheckBox;
