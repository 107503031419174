import { RoutePath } from 'enums/Routes';

export const cardRoutes = [
  RoutePath.YourName,
  RoutePath.YourPhoneNumber,
  RoutePath.VerifyPhoneNumber,
  RoutePath.YourEmail,
  RoutePath.YourIncome,
  RoutePath.MethodAuth,
];
