import React, { FC } from 'react';
import clsx from 'clsx';
import Loader from 'components/Loader';

import styles from './LinkButton.module.scss';

interface LinkButtonProps {
  isLoading: boolean;
  className?: string;
  disabled?: boolean;
  capitalized?: boolean;
  onClick: () => void;
}

const LinkButton: FC<LinkButtonProps> = ({
  children,
  className,
  onClick,
  isLoading = false,
  disabled,
  capitalized = true,
}) => (
  <div className={clsx(styles.container, className)}>
    {isLoading ? (
      <div className={styles.loaderContainer}>
        <Loader size={40} />
      </div>
    ) : (
      <div
        onClick={!disabled ? onClick : () => {}}
        className={clsx(styles.linkButton, {
          [styles.disabled]: disabled,
          [styles.capitalized]: capitalized,
        })}
      >
        {children}
      </div>
    )}
  </div>
);

export default LinkButton;
