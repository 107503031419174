import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, DataForUpdatingApplicationCitizenship } from 'api/ApplicationDataApi';

export const createCitizenshipThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('/application/citizenship', async (data: DataForUpdatingApplicationCitizenship, thunkApi) => {
    try {
      await api.updateApplicationCitizenship(data);
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
