import { useEffect, useRef, useState } from 'react';

/**
 * Dropdown functionality hook. Use it when the dropdown content is dynamic.
 * For static or fixed heights, you might want to use css instead
 * @returns An object with the dropdown state and handlers.
 */
const useDropdown = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, toggleDropdown] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);

  const handleToggleDropdown = () => {
    toggleDropdown(!isOpen);
  };

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setHeight(contentRef.current.scrollHeight ?? 0);
    } else {
      setHeight(0);
    }
  }, [isOpen, contentRef]);

  return {
    isOpen,
    handleToggleDropdown,
    height,
    contentRef,
  };
};

export default useDropdown;
