import React, { FC } from 'react';
import clsx from 'clsx';
import { Message } from 'react-hook-form';

import styles from './InputError.module.scss';

interface InputErrorProps {
  errorMessage?: Message | undefined;
  className?: string;
}

const InputError: FC<InputErrorProps> = ({ errorMessage, className }) => (
  <p className={clsx(styles.inputError, className)}>{errorMessage}</p>
);

export default InputError;
