import React from 'react';
import clsx from 'clsx';

import styles from './Checkbox.module.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element;
}

const Checkbox = ({ label, onChange, className, checked, ...rest }: CheckboxProps) => (
  <div className={className}>
    <label className={styles.container}>
      <div className={styles.checkboxLabel}>{label}</div>
      <input type="checkbox" {...rest} onChange={onChange} checked={checked} />
      <span className={clsx(styles.checkmark, styles.checkboxCheckmark)} />
    </label>
  </div>
);

export default Checkbox;
