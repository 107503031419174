import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi } from 'api/ApplicationDataApi';

export const createUpdateApplicationCheckingAccountThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/updateUserBankAccountData', async (data: any, thunkApi) => {
    try {
      await api.updateApplicationCheckingAccount(data.applicationId, data.bankAccountId);
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);
    }
  });
