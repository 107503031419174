import React, { FC, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import clsx from 'clsx';

import { ReactComponent as SelectArrow } from 'images/select-arrow.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';
import Input from 'components/Input';

import styles from './InputSelect.module.scss';

export interface InputSelectOption {
  value: string;
  label: string;
}

interface InputSelectProps {
  placeholder?: string;
  options: InputSelectOption[];
  value: string | undefined;
  onChange: (option: InputSelectOption) => void;
  className?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const InputSelect: FC<InputSelectProps> = ({
  placeholder,
  options,
  value,
  onChange,
  className,
  name,
  label,
  disabled,
  onBlur,
}) => {
  const [open, setOpen] = useState(false);

  const handleSelect = (selectedOption: InputSelectOption) => () => {
    const currentOption = options.find(({ value: selectValue }) => selectValue === selectedOption.value)!;
    onChange(currentOption);
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentOption = options.find(
      (selectedOption) =>
        selectedOption.value === event.currentTarget.value || selectedOption.label === event.currentTarget.value,
    )!;
    if (currentOption) {
      onChange(currentOption);
      setOpen(false);
    }
  };

  const getValue = () => {
    const currentOption = options.find((option) => option.value === value);

    return currentOption ? currentOption.label : '';
  };

  const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });

  return (
    <div className={className}>
      {label && <p className={styles.label}>{label}</p>}
      <div className={clsx(styles.inputSelectContainer)} ref={ref}>
        <Input
          placeholder={placeholder}
          className={styles.inputSelect}
          onClick={() => setOpen(true)}
          onChange={(event) => handleChange(event)}
          value={getValue()}
          name={name}
          disabled={disabled}
          onBlur={onBlur}
        />
        <SelectArrow className={clsx(styles.selectArrow, open && styles.selectArrowActive)} />
        {open && (
          <ul className={styles.dropdownOptionsList}>
            {options.map((optionItem, index) => (
              <div key={index} className={styles.dropdownOptionContainer}>
                <li className={styles.dropdownOption} onClick={handleSelect(optionItem)}>
                  {optionItem.label ? optionItem.label : optionItem.value}
                </li>
                {optionItem.value === value && (
                  <div className={styles.checkIcon}>
                    <CheckIcon />
                  </div>
                )}
              </div>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default InputSelect;
