import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAutoScroll from 'hooks/useAutoScroll';
import useQueryParam, { useQueryParams } from 'hooks/useQueryParam';
import { useNavigate } from 'hooks/useNavigate';
import { setApplicationFlow, setApplicationStep } from 'handlers/applicationStep';
import { PreQualificationData, setPartnerBranding, setGoal } from 'handlers/preQualificationData';
import { setCardData } from 'handlers/cardData';
import { setReferralApplicationId } from 'handlers/referrals';
import { getApplicationData, getCardDataForLoanApplication, getUserLocation } from 'thunks';
import { getState } from 'selectors/getState';
import { getApplicationData as applicationData } from 'selectors/getApplicationData';
import { getApplicationStep } from 'selectors/getApplicationStep';
import { RoutePath } from 'enums/Routes';
import { CurrentFlow } from 'enums/CurrentFlow';
import { PartnerBrandingName } from 'enums/PartnerBrandingName';
import { LoanGoalName } from 'enums/LoanGoalName';
import { UtmCampaign, UtmSource } from 'enums/UtmTagName';
import { Environments, getEnvironment } from 'utils/getEnvironment';
import { COLOR_PARAM_TO_CARD_COLOR } from 'components/CardFlow/Customize/Customize';
import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import { setFullName, splitFullName } from 'handlers/yourName';
import { ProfessionGroup } from 'enums/ProfessionGroup';
import { setProfessionGroup } from 'handlers/professionGroup';
import { UrlParams } from 'enums/UrlParams';
import { FLOW_ARG_TO_CURRENT_FLOW } from 'utils/flowArgToCurrentFlow';
import Root from 'pages/Root';
import PageLoader from 'components/PageLoader';

import FlowRouter, { CustomRouterType } from './FlowRouter';

const FLOW_CHANGE_ROUTES = [RoutePath.XSellLoader];

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useAutoScroll();

  const state = useSelector(getState);
  const { stepName, currentFlow } = getApplicationStep(state);
  const { application, isLoading: isLoadingApplicaiton } = applicationData(state);

  const flags = useFlags();
  const params = useQueryParams();
  const isResumeProcess = params.has(UrlParams.ResumeProcess);
  const flow = useQueryParam(UrlParams.Flow);
  const applicationId = useQueryParam(UrlParams.ResumeProcess);

  const utmSource = useQueryParam(UrlParams.UtmSource);
  const utmContent = useQueryParam(UrlParams.UtmContent);
  const utmCampaign = useQueryParam(UrlParams.UtmCampaign);
  const initialReferrer = useQueryParam(UrlParams.InitialReferrer);

  const fullName = useQueryParam(UrlParams.FullName);
  const credentials = useQueryParam(UrlParams.Credentials);
  const cardColor: CardColor | undefined = COLOR_PARAM_TO_CARD_COLOR[useQueryParam(UrlParams.CardColor) || ''];
  const professionGroup = params.get(UrlParams.ProfessionGroup);

  const getExistingPath = (path: string): RoutePath | undefined =>
    Object.values(RoutePath).find((existingPath) => existingPath === path);

  const handleInitialQueryParams = () => {
    const partnerParam = searchParams.get(UrlParams.Partner);
    const existingPartner =
      getEnvironment() === Environments.Staging
        ? (partnerParam as PartnerBrandingName)
        : Object.values(PartnerBrandingName).find((partner) => partner === partnerParam);
    if (existingPartner) {
      const preQualificationData: PreQualificationData = {
        partner_branding: existingPartner!,
      };
      dispatch(setPartnerBranding(preQualificationData));
    }

    const goalParam = searchParams.get(UrlParams.Goal);
    if (goalParam) {
      const existingGoal = Object.entries(LoanGoalName).find(([key]) => key === goalParam)?.[1];
      const goal = existingGoal || goalParam;
      dispatch(setGoal(goal));
    }

    if (utmSource === UtmSource.Referral) {
      const referrer = utmContent || '';
      const isCardReferral = utmCampaign === UtmCampaign.PlanneryCard;
      if (isCardReferral) {
        dispatch(
          setCardData({
            referredBy: referrer,
            initialReferrer: initialReferrer || '',
          }),
        );
      } else {
        dispatch(setReferralApplicationId(referrer));
      }
    }

    if (fullName) {
      dispatch(setFullName({ fullName }));
    }

    if (credentials) {
      dispatch(setCardData({ borrowerCredentials: credentials }));
    }

    if (cardColor) {
      dispatch(setCardData({ cardColor }));
    }

    if (professionGroup) {
      dispatch(setProfessionGroup(professionGroup as ProfessionGroup));
    }
  };

  useEffect(() => {
    const { firstName, lastName } = splitFullName(fullName);

    analytics.identify({ flags, professionGroup, cardColor, name: fullName, firstName, lastName, credentials });

    if (stepName === undefined && getExistingPath(location.pathname) && currentFlow === undefined) {
      // Backwards compatibility for old applications
      dispatch(
        setApplicationFlow({ currentFlow: CurrentFlow.FinancialCheckup, stepName: getExistingPath(location.pathname) }),
      );
    }

    // If there is a resume process
    if (isResumeProcess && !application && !isLoadingApplicaiton) {
      dispatchWithUnwrap(getApplicationData(applicationId!)).then((response) => {
        if (response.application.cardApplied) {
          dispatchWithUnwrap(getCardDataForLoanApplication(response.application.id));
        }
        dispatch(
          setCardData({
            applied: response.application.cardApplied,
          }),
        );
        analytics.identify(response.application.borrowerId);
        (window as any).nid('setUserId', response.application.id);
      });
    }

    // if there is no step data and resume process, set flow and stepname
    if (!isResumeProcess && !stepName && !getExistingPath(location.pathname)) {
      dispatch(
        setApplicationFlow({
          currentFlow: FLOW_ARG_TO_CURRENT_FLOW[flow!] || CurrentFlow.FinancialCheckup,
          completedCustomize: Boolean(
            firstName &&
              lastName &&
              !lastName.includes(' ') &&
              cardColor &&
              (credentials || professionGroup === ProfessionGroup.Healthcare) &&
              professionGroup,
          ),
        }),
      );
    }

    const trackUserLocation = async () => {
      const userLocation = await dispatchWithUnwrap(getUserLocation());
      if (userLocation?.clientCountryRegion) {
        analytics.identify({
          clientCountry: userLocation.clientCountryRegion.substr(0, 2),
          clientRegion: userLocation.clientCountryRegion.substr(2),
          clientCity: userLocation.clientCity,
        });
      }
    };

    handleInitialQueryParams();
    trackUserLocation();
  }, []);

  useEffect(() => {
    const pathName = getExistingPath(location.pathname);
    // Navigate to stepName if the current location does not exist or the current route expects a flow change
    if (stepName && (!pathName || FLOW_CHANGE_ROUTES.includes(pathName)) && stepName !== location.pathname) {
      navigate(stepName, { replace: !pathName });
    }
  }, [stepName]);

  useEffect(() => {
    if (location) {
      const actualPath = getExistingPath(location.pathname);
      if (actualPath) {
        dispatch(setApplicationStep(actualPath));
      }
    }
  }, [location]);

  if (isResumeProcess && isLoadingApplicaiton && !application) {
    return (
      <Routes>
        <Route path="*" element={<PageLoader />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Root />}>
        {currentFlow !== undefined &&
          Object.keys(FlowRouter[currentFlow]).map((path) => {
            const { component: Component, navigationInfo, handleNext } = FlowRouter[currentFlow][
              path as RoutePath
            ] as CustomRouterType;
            return (
              <Route
                path={path}
                element={
                  <Component
                    flags={flags}
                    navigationInfo={navigationInfo || {}}
                    handleNext={
                      handleNext ? handleNext({ state, navigate, flags, dispatch, dispatchWithUnwrap }) : () => {}
                    }
                  />
                }
                key={path}
              />
            );
          })}
      </Route>
    </Routes>
  );
};
