import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayrollProviderName } from 'api/EmployersApi';

export interface PayrollProvider<ProviderName extends string> {
  name: ProviderName;
  enabled: boolean;
}

interface PayrollProvidersState {
  providers: PayrollProvider<PayrollProviderName>[];
}

interface TogglePayrollProviderPayload {
  provider: PayrollProviderName;
}

const initialState: PayrollProvidersState = {
  providers: [
    {
      name: PayrollProviderName.Argyle,
      enabled: true,
    },
    {
      name: PayrollProviderName.Pinwheel,
      enabled: true,
    },
    {
      name: PayrollProviderName.Atomic,
      enabled: false,
    },
  ],
};

const payrollProviders = createSlice({
  name: 'payrollProviders',
  initialState,
  reducers: {
    disablePayrollProvider: (state, { payload }: PayloadAction<TogglePayrollProviderPayload>) => {
      const { provider: providerName } = payload;
      const provider = state.providers.find(({ name }) => name === providerName)!;
      provider.enabled = false;
    },
  },
});

export const { disablePayrollProvider } = payrollProviders.actions;

export default payrollProviders.reducer;
