import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import FormContainer from 'components/LoanForm/FormContainer';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as UserIcon } from 'images/profile.svg';
import Button from 'components/Button';
import { FlowComponentType } from 'routes/FlowRouter';
import FormNavigation from 'components/FormNavigation';
import { getRepaymentLabel } from 'components/Card/StudentLoanForgiveness/commonCalculations';

import styles from './StudentLoanSavings.module.scss';

const colleagues = Object.freeze([
  {
    name: 'Katie K',
    savings: 131080,
  },
  {
    name: 'Beau G',
    savings: 297235,
  },
  {
    name: 'Dr. Russel F',
    savings: 647235,
  },
  {
    name: 'Whitney D',
    savings: 77240,
  },
]);

const StudentLoanSavings = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { monthSavings, monthsToStandardRepayment, monthsToPublicServiceRepayment } = useSelector(getStudentLoanData);
  const label = getRepaymentLabel(monthSavings ?? 0);
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title={
          <>
            Act Now! <br /> You could be out of debt
          </>
        }
      >
        <p className={styles.yearsToSaveLabel}>{label}</p>

        <p className={clsx(styles.label, styles.spaced)}>
          You may qualify for federal student loan forgiveness. <strong>Act by December 31, 2023</strong> to unlock
          maximum savings.
        </p>

        <p className={styles.label}>This is an estimate based on what we know about you.</p>

        <div className={styles.compareWrapper}>
          <div className={styles.compareTable}>
            <div className={styles.tableHeader}>
              <p>Standard Repayment</p>
            </div>
            <div className={styles.tableBody}>
              <strong>{monthsToStandardRepayment} months</strong>
              <p>to pay off</p>
            </div>
          </div>
          <div className={clsx(styles.compareTable, styles.purple)}>
            <div className={styles.tableHeader}>
              <p>Public Service Loan Forgiveness</p>
            </div>
            <div className={styles.tableBody}>
              <strong>{monthsToPublicServiceRepayment} months</strong>
              <p>to pay off</p>
            </div>
          </div>
        </div>

        <p className={styles.label}>Join your colleagues who have gotten life changing savings.</p>

        <div className={styles.colleagues}>
          {colleagues.map((colleague) => (
            <div key={colleague.name.trim()} className={styles.colleagueRow}>
              <UserIcon className={styles.userIcon} />
              <p className={styles.colleagueName}>{colleague.name}</p>
              <p className={styles.colleagueSavings}>{formatMonetaryAmount(colleague.savings)}</p>
            </div>
          ))}
        </div>

        <Button className={styles.button} onClick={handleNext}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default StudentLoanSavings;
