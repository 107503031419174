import React, { FC } from 'react';
import clsx from 'clsx';
import { Message } from 'react-hook-form';

import InputError from 'components/InputError';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './Input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: Message | undefined;
  inputClassName?: string;
}

const Input: FC<InputProps> = ({ value, className, label, errorMessage, inputClassName, onBlur, ...restProps }) => {
  const { themeStyles } = useLayoutTheme();
  return (
    <div className={clsx(styles.inputContainer, className)}>
      {label && <p className={clsx(styles.label, themeStyles.inputLabel)}>{label}</p>}
      <input
        value={value || ''}
        autoComplete="off"
        {...restProps}
        className={clsx(
          styles.input,
          themeStyles.input,
          inputClassName,
          !!errorMessage && styles.error,
          !!restProps.readOnly && styles.readOnly,
        )}
        onBlur={onBlur}
      />
      {!!errorMessage && <InputError errorMessage={errorMessage} />}
    </div>
  );
};

export default Input;
