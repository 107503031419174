import React, { useEffect } from 'react';
import FormContainer from 'components/LoanForm/FormContainer';

import { ReactComponent as InboxImage } from 'images/inbox.svg';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { sendDirectDepositEnrollmentFormEmail } from 'thunks';
import BackLink from 'components/BackLink';

import styles from './SetupViaHr.module.scss';

const SetupViaHr = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { id: applicationId } = useSelector(getApplicationData).application!;

  useEffect(() => {
    dispatchWithUnwrap(sendDirectDepositEnrollmentFormEmail(applicationId));
  }, []);

  return (
    <>
      <BackLink />
      <FormContainer
        title="Setup Via Your HR Manager"
        subtitle="Check your email for your form. Send it to the person who manages paychecks for your employer."
      >
        <div className={styles.content}>
          <InboxImage />
        </div>
      </FormContainer>
    </>
  );
};

export default SetupViaHr;
