import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { updateCitizenshipData } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import FormContainer from 'components/LoanForm/FormContainer';
import CountrySelect from 'components/CountrySelect';
import { RootState } from 'handlers';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './Citizenship.module.scss';

const Citizenship = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const borrowerCitizenship = useSelector((state: RootState) => state.applicationData.application?.borrowerCitizenship);
  const [citizenship, setCitizenship] = useState<string | null>(borrowerCitizenship!);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isValid = () => citizenship;

  useEffect(() => {
    if (borrowerCitizenship) {
      handleNext();
    }
  }, []);

  const submit = async () => {
    if (!citizenship) {
      return;
    }

    setIsSubmitting(true);
    await dispatchWithUnwrap(
      updateCitizenshipData({
        applicationId: application!.id,
        citizenship,
      }),
    );
    setIsSubmitting(false);

    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Citizenship"
        subtitle="Select the country where you hold citizenship."
        className={styles.formContainer}
      >
        <CountrySelect
          label="Country of Citizenship"
          onChange={(country) => setCitizenship(country)}
          value={citizenship!}
          placeHolder="Select Country"
          className={styles.countrySelect}
        />
        <Button isLoading={isSubmitting} disabled={!isValid()} className={styles.button} onClick={submit}>
          Submit
        </Button>
      </FormContainer>
    </>
  );
};

export default withLDConsumer()(Citizenship);
