import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentAccountApi, PaymentAccountParams } from 'api/PaymentAccountApi';

export const createGetPaymentAccountThunk = (api: PaymentAccountApi) =>
  createAsyncThunk('/banking/getPaymentAccount', async (params: PaymentAccountParams, thunkApi) => {
    try {
      const data = await api.getPaymentAccount(params);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  });
