import { Themes } from 'styles/themes/ThemeTypes';

import { ReactComponent as BlackCard } from 'images/cards/black-card-front.svg';
import { ReactComponent as BlackCardBack } from 'images/cards/black-card-back.svg';
import { ReactComponent as BlueCard } from 'images/cards/blue-card-front.svg';
import { ReactComponent as BlueCardBack } from 'images/cards/blue-card-back.svg';
import { ReactComponent as WhiteCard } from 'images/cards/white-card-front.svg';
import { ReactComponent as WhiteCardBack } from 'images/cards/white-card-back.svg';

import { ReactComponent as PurpleSilverCard } from 'images/cards/purple-silver-card-front.svg';
import { ReactComponent as PurpleSilverCardBack } from 'images/cards/purple-silver-card-back.svg';
import { ReactComponent as LightPurpleCard } from 'images/cards/light-purple-card-front.svg';
import { ReactComponent as LightPurpleCardBack } from 'images/cards/light-purple-card-back.svg';
import { ReactComponent as GoldCard } from 'images/cards/gold-card-front.svg';
import { ReactComponent as GoldCardBack } from 'images/cards/gold-card-back.svg';

export interface Card {
  name: string;
  theme: Themes;
  front: React.FunctionComponent;
  back: React.FunctionComponent;
}

export enum CardColor {
  Black = 'black',
  Blue = 'blue',
  White = 'white',
  PurpleSilver = 'purple_silver',
  Gold = 'gold',
  LightPurple = 'light_purple',
}

export const cards: Record<CardColor, Card> = {
  [CardColor.Black]: { name: 'Black', theme: Themes.DEFAULT, front: BlackCard, back: BlackCardBack },
  [CardColor.Blue]: { name: 'Blue', theme: Themes.DEFAULT, front: BlueCard, back: BlueCardBack },
  [CardColor.White]: { name: 'White', theme: Themes.DEFAULT, front: WhiteCard, back: WhiteCardBack },
  [CardColor.PurpleSilver]: {
    name: 'Silver',
    theme: Themes.DEFAULT,
    front: PurpleSilverCard,
    back: PurpleSilverCardBack,
  },
  [CardColor.Gold]: { name: 'Gold', theme: Themes.DEFAULT, front: GoldCard, back: GoldCardBack },
  [CardColor.LightPurple]: {
    name: 'Purple',
    theme: Themes.DEFAULT,
    front: LightPurpleCard,
    back: LightPurpleCardBack,
  },
} as const;

export interface ColorSet {
  colors: CardColor[];
  defaultColor: CardColor;
}

export const colorSets: Record<string, ColorSet> = {
  '1': { colors: [CardColor.Black, CardColor.Blue, CardColor.White], defaultColor: CardColor.Black },
  '2': { colors: [CardColor.PurpleSilver, CardColor.Gold, CardColor.LightPurple], defaultColor: CardColor.Gold },
};

export const DEFAULT_COLOR_SET = colorSets['2'];
