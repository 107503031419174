import { ReferralProgramID } from 'handlers/cardData';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';

export const LAUNCH_WAITLIST_SIZE = 10_000;

interface VariantData {
  totalCount: number;
  currentCount: number;
  originalPosition: number;
}

const useReferralProgramData = (): VariantData => {
  const { referralProgram, waitListPosition, originalWaitListPosition, waitListLength } = useSelector(getCardData);

  const { id, rewards } = referralProgram!;

  const data = (): VariantData => {
    switch (id) {
      case ReferralProgramID.NoRewards:
        return {
          totalCount: 0,
          currentCount: 0,
          originalPosition: 0,
        };
      case ReferralProgramID.LaunchProgress:
        return {
          totalCount: LAUNCH_WAITLIST_SIZE,
          currentCount: waitListLength ?? 0,
          originalPosition: 0,
        };
      case ReferralProgramID.Waitlist:
        return {
          totalCount: waitListLength ?? 0,
          currentCount: waitListPosition ?? 0,
          originalPosition: originalWaitListPosition ?? 0,
        };
      case ReferralProgramID.CashBack25:
        return {
          totalCount: rewards[0].maxRedemption * 25,
          currentCount: rewards[0].timesRedeemed * 25,
          originalPosition: 0,
        };
      case ReferralProgramID.Gales:
      default:
        return {
          totalCount: 0,
          currentCount: 0,
          originalPosition: 0,
        };
    }
  };

  return data();
};

export default useReferralProgramData;
