import { createAsyncThunk } from '@reduxjs/toolkit';
import DocuSignEnvelopeRestApi, { DataForCreatingDocuSignEnvelope } from 'api/DocuSignEnvelopeApi';

export const createDocuSignEnvelopeThunk = (api: DocuSignEnvelopeRestApi) =>
  createAsyncThunk(
    'docuSignEnvelope/createDocuSignEnvelope',
    async (data: DataForCreatingDocuSignEnvelope, thunkApi) => {
      try {
        return await api.createEnvelope(data);
      } catch (error) {
        thunkApi.rejectWithValue(error.response.data);

        throw error;
      }
    },
  );
