import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlloyCheckResult } from 'api/AlloyApi';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { alloyKyc } from 'thunks';

export enum KycStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  ManualReview = 'Manual Review',
  Pending = 'Pending',
  PendingDocuments = 'Pending Documents',
}

export interface KycResult {
  status: ApplicationStatusName;
}

export interface AlloyKycResult extends KycResult {
  journeyApplicationToken: string;
  journeyToken: string;
}

interface KycState {
  isLoading: boolean;
  status?: KycStatus;
  journeyApplicationToken?: string;
  journeyToken?: string;
  kycSkipped: boolean;
}

const initialState: KycState = {
  isLoading: false,
  kycSkipped: false,
};

const kyc = createSlice({
  name: 'kyc',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(alloyKyc.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(alloyKyc.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(alloyKyc.fulfilled, (state, { payload }: PayloadAction<AlloyCheckResult>) => {
      state.isLoading = false;
      state.journeyApplicationToken = payload.journeyApplicationToken;
      state.journeyToken = payload.journeyToken;
      state.status = payload.status;
    });
  },
});

export default kyc.reducer;
