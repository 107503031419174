export enum StudentLoanEmployerResult {
  NonProfit = 'nonProfit',
  ForProfit = 'forProfit',
  NotSure = 'notSure',
}

export enum StudentLoanForgivenessAwarenessResult {
  Yes = 'yes',
  No = 'no',
}

export enum StudentLoanNotEnrolledResult {
  Applying = 'applying',
  WontApprove = 'wontApprove',
  DifficultToApply = 'difficultToApply',
  WontSaveMoney = 'wontSaveMoney',
}

export enum StudentLoanBookAdvisorResult {
  Schedule = 'schedule',
  NotNow = 'notNow',
}

export type StudentLoanForgivenessResults =
  | StudentLoanEmployerResult
  | StudentLoanForgivenessAwarenessResult
  | StudentLoanNotEnrolledResult
  | StudentLoanBookAdvisorResult;
