import Api from './Api';

export interface StudentLoanAssistanceData {
  eligible: boolean;
  paymentStartDate?: string;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  monthsToStandardRepayment?: number;
  monthsToPublicServiceRepayment?: number;
}

export interface StudentLoanApi {
  getStudentLoanAssistanceData(applicationId: string, startDate?: string): Promise<StudentLoanAssistanceData>;
}

export default class StudentLoanRestApi extends Api implements StudentLoanApi {
  async getStudentLoanAssistanceData(applicationId: string, startDate?: string): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(
      `/studentLoan/assistanceData/${applicationId}/?paymentStartDate=${startDate || ''}`,
    );
  }
}
