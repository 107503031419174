import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface YourGoalData {
  goal?: string;
}

const initialState: YourGoalData = {
  goal: undefined,
};

const yourGoal = createSlice({
  name: 'yourGoal',
  initialState,
  reducers: {
    setYourGoalData: (state: YourGoalData, { payload }: PayloadAction<YourGoalData>) => {
      state.goal = payload.goal;
      analytics.identify({ loanGoal: payload.goal });
    },
  },
});

export const { setYourGoalData } = yourGoal.actions;

export default yourGoal.reducer;
