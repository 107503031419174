import React, { useEffect } from 'react';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import { useForm } from 'react-hook-form';
import { getMessageForRequiredFields } from 'utils/errors';
import FormNavigation from 'components/FormNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { setYourIncomeData } from 'handlers/yourIncome';
import { getApplicationStep } from 'selectors/getApplicationStep';
import { CurrentFlow } from 'enums/CurrentFlow';
import { YourIncomeVariable } from 'enums/LoanFormVariables';
import { FlowComponentType } from 'routes/FlowRouter';
import NumberInput from 'components/NumberInput/NumberInput';
import { ANNUAL_INCOME_MAX_LENGTH } from 'components/LoanForm/YourTotalIncome/YourTotalIncome';
import { formatDate, getYearsFromNow } from 'utils/dateUtils';
import DatePicker from 'components/DatePicker/DatePicker';
import { getYourIncome } from 'selectors/yourIncome';

import styles from './YourIncome.module.scss';

enum YourIncomeInputLabel {
  GrossAnnualIncome = 'Gross annual income',
  StartOfEmployment = 'Start date of primary employer',
}

const YourIncome = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();

  const { currentFlow } = useSelector(getApplicationStep);
  const defaultValues = useSelector(getYourIncome);

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const watcher = watch();

  const handleContinue = () => {
    dispatch(setYourIncomeData(watcher));
    handleNext();
  };

  useEffect(() => {
    register(YourIncomeVariable.TotalAnnualIncome, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.GrossAnnualIncome),
    });
    register(YourIncomeVariable.StartOfEmployment, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.StartOfEmployment),
    });
  }, [register]);

  let title = '';
  let subtitle = '';

  switch (currentFlow) {
    case CurrentFlow.Card:
      title = 'What’s your income?';
      subtitle = 'This information is needed to put together your offer.';
      break;
    case CurrentFlow.FinancialCheckup:
      title = 'What’s your income?';
      subtitle = 'We will send your offer details to the email address you provide.';
      break;
    default:
      break;
  }

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title={title} subtitle={subtitle}>
        <NumberInput
          label={YourIncomeInputLabel.GrossAnnualIncome}
          prefix="$"
          placeholder="$0"
          errorMessage={errors[YourIncomeVariable.TotalAnnualIncome]?.message}
          thousandSeparator
          className={styles.formInput}
          name={YourIncomeVariable.TotalAnnualIncome}
          onChange={(event) => {
            setValue(YourIncomeVariable.TotalAnnualIncome, Number(event.target.value.replace(/[^0-9.-]+/g, '')));
            trigger(YourIncomeVariable.TotalAnnualIncome);
          }}
          value={
            watcher[YourIncomeVariable.TotalAnnualIncome]
              ? `${watcher[YourIncomeVariable.TotalAnnualIncome]}`
              : undefined
          }
          maxLength={ANNUAL_INCOME_MAX_LENGTH}
        />
        <div className={styles.note}>
          Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
          considered as a basis for repaying this obligation.
        </div>
        <DatePicker
          maxDate={getYearsFromNow(0)}
          minDate={getYearsFromNow(-30)}
          placeHolder="MM/YYYY"
          label={YourIncomeInputLabel.StartOfEmployment}
          className={styles.formInput}
          showMonthYearPicker
          selected={
            watcher[YourIncomeVariable.StartOfEmployment]
              ? new Date(watcher[YourIncomeVariable.StartOfEmployment] as string)
              : undefined
          }
          onChange={(date) => {
            setValue(YourIncomeVariable.StartOfEmployment, formatDate(date));
            trigger(YourIncomeVariable.StartOfEmployment);
          }}
          errorMessage={errors[YourIncomeVariable.StartOfEmployment]?.message}
          name={YourIncomeVariable.StartOfEmployment}
        />

        <Button className={styles.button} disabled={!isValid} onClick={handleContinue}>
          Apply
        </Button>
      </FormContainer>
    </>
  );
};

export default YourIncome;
