import React, { FC, useCallback, useEffect, useState } from 'react';
import PinwheelModal, { EventName, EventPayload } from '@pinwheel/react-modal';
import { createPinwheelLinkToken } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { GetApplicationData } from 'handlers/applicationData';

export interface PinwheelLoginProps {
  open?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  application: GetApplicationData;
}

const PinwheelWidget: FC<PinwheelLoginProps> = ({ open, onClose, onSuccess, application }) => {
  const dispatch = useDispatchWithUnwrap();
  const [pinwheelLinkToken, setPinwheelLinkToken] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const token = await dispatch(createPinwheelLinkToken(application.id));
      setPinwheelLinkToken(token);
    })();
  }, []);

  const onEvent = useCallback((eventName: EventName, payload: EventPayload) => {
    analytics.track(`Pinwheel: ${eventName}`, payload);
  }, []);

  if (!pinwheelLinkToken) {
    return null;
  }

  return (
    <PinwheelModal open={open} onExit={onClose} linkToken={pinwheelLinkToken} onEvent={onEvent} onSuccess={onSuccess} />
  );
};

export default PinwheelWidget;
