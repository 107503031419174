import React, { FC } from 'react';

import styles from './Pill.module.scss';

export enum PillStatus {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

interface PillProps {
  label: string;
  // status: PillStatus;
}

const Pill: FC<PillProps> = ({ label }): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.label}>{label}</div>
  </div>
);

export default Pill;
