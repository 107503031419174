import { ThemeConfig } from 'hooks/useLayoutTheme';
import { createContext } from 'react';
import { Themes } from 'styles/themes/ThemeTypes';

import blueTheme from 'styles/themes/blue/themeConfig';
import purpleTheme from 'styles/themes/purple/themeConfig';
import darkTheme from 'styles/themes/dark/themeConfig';
import defaultTheme from 'styles/themes/default/themeConfig';

interface ThemeContext {
  theme: Themes;
  setTheme: (theme: Themes) => void;
}

export const themeList: Record<Themes, ThemeConfig> = {
  [Themes.BLUE]: blueTheme,
  [Themes.PURPLE]: purpleTheme,
  [Themes.DARK]: darkTheme,
  [Themes.DEFAULT]: defaultTheme,
};

export default createContext<ThemeContext>({
  theme: Themes.DEFAULT,
  setTheme: () => {},
});
