export const formatMonetaryAmount = (amount: number = 0, decimals: boolean = false): string => {
  if (amount === null) {
    return 'N/A';
  }
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimals ? 2 : 0,
  });
};
