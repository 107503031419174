import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserPayrollData } from 'thunks';

export interface EmployersLinkItemIds {
  borrower_employer_id_1?: string;
  borrower_employer_id_2?: string;
}

interface EmploymentData {
  employment: Array<Record<string, string | number | undefined>>;
  employersLinkItemIds: EmployersLinkItemIds;
}

const initialState: EmploymentData = {
  employment: [],
  employersLinkItemIds: {},
};

const employmentData = createSlice({
  name: 'employmentData',
  initialState,
  reducers: {
    setEmployerId: (state: EmploymentData, { payload }: PayloadAction<string>) => {
      if (state.employersLinkItemIds.borrower_employer_id_1) {
        state.employersLinkItemIds.borrower_employer_id_2 = payload;
      } else {
        state.employersLinkItemIds.borrower_employer_id_1 = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }) => {
      if (payload.isLinkSupported) {
        state.employment.push(payload.employmentsDataForLos as any);
      }
    });
  },
});

export const { setEmployerId } = employmentData.actions;

export default employmentData.reducer;
