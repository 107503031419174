import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as EditIcon } from 'images/edit-pen-icon.svg';
import CardPreview from 'components/ReferralProgram/CardPreview';

import styles from './CustomizeMessage.module.scss';

interface CustomizeMessageProps {
  shareMessage: string;
  setShareMessage: (message: string) => void;
}

const CustomizeMessage = ({ shareMessage, setShareMessage }: CustomizeMessageProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (inputRef.current && !inputRef.current.contains(target as Node)) {
      setIsEditing(false);
      inputRef.current.blur();
    }
  };

  const handlePressEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      window.getSelection()?.removeAllRanges(); // Removes text highlight
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.select();
    }
  }, [isEditing]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keypress', handlePressEnter);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keypress', handlePressEnter);
    };
  }, [inputRef]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Customize your message</div>

      <div className={styles.cardContainer}>
        <div className={styles.cardPreview}>
          <CardPreview />
        </div>
        <div className={styles.editMessage}>
          <textarea
            ref={inputRef}
            onChange={(e) => setShareMessage(e.target.value)}
            readOnly={!isEditing}
            className={clsx(styles.shareMessage, {
              [styles.isEditing]: isEditing,
            })}
            value={shareMessage}
          />
          {!isEditing && <EditIcon onClick={() => setIsEditing(true)} className={styles.editButton} />}
        </div>
      </div>
    </div>
  );
};

export default CustomizeMessage;
