import { UtmTags } from 'api/LoanOfferApi';
import { UtmTagName } from 'enums/UtmTagName';

export const getUtmTagsVariables = (params: URLSearchParams): UtmTags => {
  const utmTags = [
    UtmTagName.UtmSource,
    UtmTagName.UtmMedium,
    UtmTagName.UtmCampaign,
    UtmTagName.UtmContent,
  ] as UtmTagName[];

  return utmTags.reduce(
    (previousTagsVariables, tag) => ({
      ...previousTagsVariables,
      [tag]: params.get(tag),
    }),
    {},
  ) as UtmTags;
};
