import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

export interface StudentLoanAssistanceInputData {
  applicationId: string;
  startDate?: string;
}

export const createStudentLoanAssistanceDataThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/getAssistanceData', async (data: StudentLoanAssistanceInputData, thunkApi) => {
    try {
      return await api.getStudentLoanAssistanceData(data.applicationId, data.startDate);
    } catch (error: any) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
