import Api, { FetchMethodType } from './Api';

export interface PaymentAccountParams {
  applicationId: string;
  waitTimeMillis?: number;
}

export interface PaymentAccountResponse {
  success: boolean;
  error?: { message: string };
  status?: PaymentAccountStatus;
  bankAccount?: BankAccount;
}

export enum PaymentAccountStatus {
  NotStarted = 'Not Started',
  Pending = 'Pending',
  Created = 'Created',
  OptedOut = 'Opted Out',
  Invalid = 'Invalid',
}

export interface PaymentAccountApi {
  createPaymentAccount(params: PaymentAccountParams): Promise<PaymentAccountResponse>;
  getPaymentAccount(params: PaymentAccountParams): Promise<PaymentAccountResponse>;
}

export interface BankAccount {
  accountName: string;
  accountType: string;
  accountNumber: string;
  routingNumber: string;
  bankName: string;
}

export default class PaymentAccountRestApi extends Api implements PaymentAccountApi {
  public async createPaymentAccount(params: PaymentAccountParams): Promise<PaymentAccountResponse> {
    const response = await this.fetch<PaymentAccountResponse>(
      `/banking/paymentAccount/${params.applicationId}?waitTimeMillis=${params.waitTimeMillis || ''}`,
      {
        method: FetchMethodType.POST,
      },
    );
    return response;
  }

  public async getPaymentAccount(params: PaymentAccountParams): Promise<PaymentAccountResponse> {
    const response = await this.fetch<PaymentAccountResponse>(
      `/banking/paymentAccount/${params.applicationId}?waitTimeMillis=${params.waitTimeMillis || ''}`,
    );

    return response;
  }
}
