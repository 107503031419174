import { createAsyncThunk } from '@reduxjs/toolkit';
import { PinwheelApi } from 'api/PinwheelApi';

const createCreatePinwheelLinkTokenThunk = (api: PinwheelApi) =>
  createAsyncThunk('createPinwheelLinkToken', async (applicationId: string) => {
    const { token } = await api.createLinkToken(applicationId);

    return token;
  });

export default createCreatePinwheelLinkTokenThunk;
