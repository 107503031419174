import React, { useEffect } from 'react';
import Button from 'components/Button';
import Input from 'components/Input';
import FormContainer from 'components/LoanForm/FormContainer';
import { useForm } from 'react-hook-form';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { EMAIL_PATTERN, YourContactInputLabel } from 'components/LoanForm/YourContact/YourContact';
import FormNavigation from 'components/FormNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { setYourContactData } from 'handlers/yourContact';
import { getYourContactData } from 'selectors/getYourContact';
import { getApplicationStep } from 'selectors/getApplicationStep';
import { CurrentFlow } from 'enums/CurrentFlow';
import { YourContactVariable } from 'enums/LoanFormVariables';
import { FlowComponentType } from 'routes/FlowRouter';
import { getNewInputValue } from 'utils/inputHelpers';

import styles from './YourEmail.module.scss';

const YourEmail = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();

  const { email } = useSelector(getYourContactData);
  const { currentFlow } = useSelector(getApplicationStep);

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email,
    },
  });

  const watcher = watch();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      YourContactVariable.Email,
      getNewInputValue(watcher[YourContactVariable.Email], event.target.value.trim()),
    );
    trigger(YourContactVariable.Email);
  };

  const onBlur = () => {
    trigger(YourContactVariable.Email);
  };

  const handleContinue = () => {
    dispatch(setYourContactData(watcher));
    handleNext();
  };

  useEffect(() => {
    register(YourContactVariable.Email, {
      required: getMessageForRequiredFields(YourContactInputLabel.Email),
      pattern: {
        message: getMessageForInvalidFields(YourContactInputLabel.Email),
        value: EMAIL_PATTERN,
      },
    });
  }, [register]);

  let title = '';
  let subtitle = '';

  switch (currentFlow) {
    case CurrentFlow.Card:
      title = 'Access Your Offer';
      subtitle = 'We will send your offer details to the email address you provide.';
      break;
    case CurrentFlow.FinancialCheckup:
      title = 'Access Your Results';
      subtitle = 'Access your information in the future by providing your email.';
      break;
    default:
      break;
  }

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title={title} subtitle={subtitle}>
        <Input
          value={watcher[YourContactVariable.Email]}
          className={styles.formInput}
          onChange={onChange}
          onBlur={onBlur}
          label={YourContactInputLabel.Email}
          placeholder="youremail@mail.com"
          errorMessage={errors[YourContactVariable.Email]?.message}
          autoComplete="email"
        />

        <Button className={styles.button} disabled={!isValid} onClick={handleContinue}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default YourEmail;
