import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import React, { useState } from 'react';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';

import Question from './SurveyQuestion';

import { Answer, QuestionType, SurveyOption, mainSurveyQuestions, subsequentQuestions } from './surveyQuestions';

const screenDescription = {
  submitted: {
    title: 'Thank you for your feedback!',
    subtitle: 'We appreciate your time and will use your feedback to improve our service.',
  },
  default: {
    title: 'Before you go...',
    subtitle: 'It looks like you left before submitting on application. Can you tell us why?',
  },
};

const ExitSurvey = ({ navigationInfo }: FlowComponentType) => {
  const [answers, setAnswers] = useState<Answer>({});
  const [mainQuestionsFinished, setMainQuestionsFinished] = useState<boolean>(false);
  const [nextQuestions, setNextQuestions] = useState<QuestionType[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const isValid = Object.keys(answers).length > 0;
  const shouldSubmit = mainQuestionsFinished || nextQuestions.length === 0;

  const handleClick = (question: string, option: SurveyOption) => {
    setAnswers((prevState) => {
      const { value: optionValue, type } = option;
      const currentQuestion = prevState[question];

      if (!currentQuestion?.includes(optionValue)) {
        if (type) {
          setNextQuestions([...nextQuestions, type]);
        }
        return { ...prevState, [question]: currentQuestion ? [...currentQuestion, optionValue] : [optionValue] };
      }

      if (type) {
        setNextQuestions(nextQuestions.filter((item) => item !== type));
      }

      const filtered = currentQuestion.filter((item) => item !== optionValue);
      const updatedState = { ...prevState, [question]: filtered.length ? filtered : undefined };

      // Remove undefined values from the state
      Object.keys(updatedState).forEach((key) => updatedState[key] === undefined && delete updatedState[key]);

      return {
        ...updatedState,
        ...(filtered.length && { [question]: filtered }),
      };
    });
  };

  const handleSubmit = () => {
    if (!shouldSubmit) {
      setMainQuestionsFinished(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    analytics.track('Exit Survey', {
      answers,
    });
    setSubmitted(true);
  };

  const handleRedirect = () => {
    window.location.href = process.env.REACT_APP_PLANNERY_LANDING_URL || 'https://planneryapp.com';
  };

  return (
    <>
      <FormNavigation
        {...navigationInfo}
        onBackClick={() => setMainQuestionsFinished(false)}
        showBackLink={mainQuestionsFinished && !submitted}
      />
      <FormContainer {...(submitted ? screenDescription.submitted : screenDescription.default)}>
        {!submitted && (
          <>
            {!mainQuestionsFinished ? (
              <Question item={mainSurveyQuestions} answers={answers} onClick={handleClick} />
            ) : (
              subsequentQuestions.map(
                (item, index) =>
                  nextQuestions.includes(item.type) && (
                    <Question item={item} answers={answers} onClick={handleClick} key={`${index}-${item.question}`} />
                  ),
              )
            )}
            <Button disabled={!isValid} onClick={handleSubmit}>
              {shouldSubmit ? 'Submit' : 'Next'}
            </Button>
          </>
        )}

        {submitted && <Button onClick={handleRedirect}>Go back to the homepage</Button>}
      </FormContainer>
    </>
  );
};

export default ExitSurvey;
