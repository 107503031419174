export const sha256 = async (...message: string[]) =>
  Array.from(
    new Uint8Array(
      // hash the message
      await crypto.subtle.digest(
        'SHA-256',
        // encode as (utf-8) Uint8Array
        new TextEncoder().encode(message.join()),
      ),
    ),
  )
    // convert buffer to byte array
    .map((b) => b.toString(16).padStart(2, '0'))
    // convert bytes to hex string
    .join('');
