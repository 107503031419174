import { createAsyncThunk } from '@reduxjs/toolkit';
import { PlaidApi } from 'api/PlaidApi';

export interface BankAccountParam {
  applicationId: string | null;
  publicToken: string;
}

export const createGetCheckingAccounts = (api: PlaidApi) =>
  createAsyncThunk('getCheckingAccounts', async (payload: BankAccountParam) => {
    const { applicationId, publicToken } = payload;
    if (applicationId && publicToken) {
      const checkingAccounts = await api.getCheckingAccounts(applicationId, publicToken);
      return checkingAccounts;
    }
    return [];
  });

export default createGetCheckingAccounts;
