import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { BankAccount, PaymentAccountResponse, PaymentAccountStatus } from 'api/PaymentAccountApi';
import { createPaymentAccount, getPaymentAccount } from 'thunks';

interface PaymentAccountState {
  status: {
    createPaymentAccount: {
      isLoading: boolean;
    };
    getPaymentAccount: {
      hasStarted: boolean;
      isLoading: boolean;
    };
  };
  optedOut: boolean;
  isInvalid: boolean;
  bankAccountData?: BankAccount;
}

const initialState: PaymentAccountState = {
  status: {
    createPaymentAccount: {
      isLoading: false,
    },
    getPaymentAccount: {
      hasStarted: false,
      isLoading: false,
    },
  },
  optedOut: false,
  isInvalid: false,
};

const paymentAccountFulfilled = (state: Draft<PaymentAccountState>, payload: PaymentAccountResponse) => {
  state.bankAccountData = payload.bankAccount!;
  if (payload.status === PaymentAccountStatus.Invalid) {
    state.isInvalid = true;
  } else if (payload.status === PaymentAccountStatus.OptedOut) {
    state.optedOut = true;
  }
};

const paymentAccount = createSlice({
  name: 'paymentAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPaymentAccount.pending, (state) => {
      state.status.createPaymentAccount.isLoading = true;
    });
    builder.addCase(createPaymentAccount.fulfilled, (state, { payload }: PayloadAction<PaymentAccountResponse>) => {
      state.status.createPaymentAccount.isLoading = false;
      paymentAccountFulfilled(state, payload);
    });
    builder.addCase(createPaymentAccount.rejected, (state) => {
      state.status.createPaymentAccount.isLoading = false;
    });

    builder.addCase(getPaymentAccount.pending, (state) => {
      state.status.getPaymentAccount.isLoading = true;
      state.status.getPaymentAccount.hasStarted = true;
    });
    builder.addCase(getPaymentAccount.fulfilled, (state, { payload }: PayloadAction<PaymentAccountResponse>) => {
      state.status.getPaymentAccount.isLoading = false;
      paymentAccountFulfilled(state, payload);
    });
    builder.addCase(getPaymentAccount.rejected, (state) => {
      state.status.getPaymentAccount.isLoading = false;
    });
  },
});

export default paymentAccount.reducer;
