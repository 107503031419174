import React, { useEffect } from 'react';
import { PayrollProviderName } from 'api/EmployersApi';
import { getApplicationData } from 'selectors/getApplicationData';
import { useSelector } from 'react-redux';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';
import { FlowComponentType } from 'routes/FlowRouter';

import ManualDeduction from './ManualDeduction';
import AutomaticDeduction from './AutomaticDeduction';

const SetupDeduction = ({ flags, navigationInfo, handleNext }: FlowComponentType) => {
  const navigate = useNavigate();

  const { application } = useSelector(getApplicationData);

  const payrollProvider = application?.employment.employer1?.payrollProvider ?? PayrollProviderName.Manual;

  useEffect(() => {
    if (application?.manualDeductionEmailSent) {
      navigate(RoutePath.VerifyPayrollDeduction);
    }
  }, []);

  return (
    <div>
      {payrollProvider !== PayrollProviderName.Manual ? (
        <AutomaticDeduction flags={flags} navigationInfo={navigationInfo} handleNext={handleNext} />
      ) : (
        <ManualDeduction />
      )}
    </div>
  );
};

export default SetupDeduction;
