import React from 'react';

import styles from './GalesPreview.module.scss';

const galesVideo = 'https://player.vimeo.com/video/496744754?loop=1&autoplay=1&showinfo=0&muted=1&controls=0';

const GalesPreview = () => (
  <div className={styles.container}>
    <div className={styles.videoContainer}>
      <iframe src={galesVideo} title="Gales preview" />
    </div>

    <div className={styles.content}>
      <p className={styles.text}>Stylish shoes for nurses on their feet</p>
      <p className={styles.strong}>Retail value: $100-$120</p>
    </div>
  </div>
);

export default GalesPreview;
