import { createSlice } from '@reduxjs/toolkit';

interface UserHistory {
  seenOffer: boolean;
  seenOfferFeedback: boolean;
}

export const initialState: UserHistory = {
  seenOffer: false,
  seenOfferFeedback: false,
};

const userHistory = createSlice({
  name: 'userHistory',
  initialState,
  reducers: {
    setSeenOffer: (state: UserHistory) => {
      state.seenOffer = true;
    },
    setSeenOfferFeedback: (state: UserHistory) => {
      state.seenOfferFeedback = true;
    },
  },
});

export const { setSeenOffer, setSeenOfferFeedback } = userHistory.actions;

export default userHistory.reducer;
