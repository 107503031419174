import React from 'react';
import { verificationSteps } from 'components/Verification/verificationSteps';

import styles from './FirstTimeFlow.module.scss';

const FirstTimeFlow = () => (
  <div className={styles.container}>
    {verificationSteps.map((step) => (
      <div key={step.id} className={styles.step}>
        <div className={styles.stepDot} />
        <step.icon className={styles.icon} />
        <div className={styles.name}>{step.name}</div>
      </div>
    ))}
  </div>
);

export default FirstTimeFlow;
