import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserPayrollData } from 'thunks';
import { CreditReportVariable } from 'enums/LoanFormVariables';
import { GetUserDataResponse } from 'api/UserDataApi';

export interface CreditReportArgyleResponse {
  [CreditReportVariable.SocialSecurityNumber]: string;
}

interface CreditReportInterface {
  [CreditReportVariable.SocialSecurityNumber]: string;
}

export const initialState: CreditReportInterface = {
  [CreditReportVariable.SocialSecurityNumber]: '',
};

const creditReport = createSlice({
  name: 'creditReport',
  initialState,
  reducers: {
    setCreditReportData: (state: CreditReportInterface, { payload }: PayloadAction<CreditReportInterface>) => {
      state[CreditReportVariable.SocialSecurityNumber] = payload[CreditReportVariable.SocialSecurityNumber];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }: PayloadAction<GetUserDataResponse>) => {
      if (payload.isLinkSupported) {
        state[CreditReportVariable.SocialSecurityNumber] = !state[CreditReportVariable.SocialSecurityNumber]
          ? payload.creditReport[CreditReportVariable.SocialSecurityNumber]
          : state[CreditReportVariable.SocialSecurityNumber];
      }
    });
  },
});

export const { setCreditReportData } = creditReport.actions;

export default creditReport.reducer;
