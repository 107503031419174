import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import styles from './RewardProgressBar.module.scss';

interface RewardProgressProps {
  title?: string;
  totalCount: number;
  currentCount: number;
  originalPosition: number;
  showPositionLabel: boolean;
  showCount: boolean;
  isCountMoney?: boolean;
  invertedCount?: boolean; // Makes the progress go from max to min (e.g. 1000 to 0)
}

const RewardProgress = ({
  title,
  totalCount,
  currentCount,
  originalPosition,
  showPositionLabel,
  showCount,
  isCountMoney = false,
  invertedCount,
}: RewardProgressProps) => {
  const progressNormalized = Math.round((currentCount / totalCount) * 100);
  const progressPercentage = invertedCount ? 100 - progressNormalized : progressNormalized;
  const originalPositionNormalized = Math.round((originalPosition / totalCount) * 100);
  const originalPositionPercentage = invertedCount ? 100 - originalPositionNormalized : originalPositionNormalized;
  const hideLabels = progressPercentage === originalPositionPercentage;

  const overflowingOriginalLeft = originalPositionPercentage < 5;
  const overlappingLabels =
    progressPercentage - originalPositionPercentage < 8 ||
    (overflowingOriginalLeft && progressPercentage - originalPositionPercentage < 14);
  const overlappingWhileOverflowing = progressPercentage - originalPositionPercentage <= 18;

  const overflowingCurrentLeft =
    progressPercentage < 5 || (overflowingOriginalLeft && overlappingWhileOverflowing && !overlappingLabels);
  const overflowingCurrentRight = progressPercentage > 89;

  return (
    <div
      className={clsx(styles.container, {
        [styles.withLabel]: showPositionLabel,
      })}
    >
      <div className={styles.progressData}>
        <p className={styles.title}>{title}</p>
        {showCount && (
          <p className={styles.count}>
            {isCountMoney ? '$' : ''}
            {currentCount.toLocaleString()} / {isCountMoney ? '$' : ''}
            {totalCount.toLocaleString()}
          </p>
        )}
      </div>

      <div className={styles.progressBarContainer}>
        <div
          className={clsx(styles.progressBarCompleted, {
            [styles.withLabel]: showPositionLabel,
          })}
          style={{ '--progress': `${progressPercentage}%` } as CSSProperties}
        >
          {showPositionLabel && (
            <>
              <div
                className={clsx(styles.progressLabel, {
                  [styles.overflowingLeft]: overflowingCurrentLeft,
                  [styles.overflowingRight]: overflowingCurrentRight,
                })}
              >
                <p className={styles.labelName}>Current</p>
                <p>{currentCount.toLocaleString()}</p>
              </div>
            </>
          )}
        </div>
        {showPositionLabel && !hideLabels && (
          <div
            className={styles.originalPosition}
            style={{ '--position': `${originalPositionPercentage}%` } as CSSProperties}
          >
            <div
              className={clsx(styles.originalPositionLabel, {
                [styles.hidden]: overlappingLabels,
                [styles.overflowingLeft]: overflowingOriginalLeft,
              })}
            >
              <p className={styles.labelName}>Original</p>
              <p>{originalPosition.toLocaleString()}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default RewardProgress;
