import React, { useState } from 'react';

import styles from './ShowDetailsLabel.module.scss';

interface ShowDetailsLabelProps {
  content: JSX.Element;
}

const ShowDetailsLabel = ({ content }: ShowDetailsLabelProps) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <span className={styles.container}>
      <span onClick={() => setShowDetails(!showDetails)} className={styles.button}>
        {showDetails ? 'Hide' : 'Show'} Details
      </span>
      {showDetails && <div className={styles.content}>{content}</div>}
    </span>
  );
};

export default ShowDetailsLabel;
