import React from 'react';
import clsx from 'clsx';

import styles from './StatusButton.module.scss';

interface StatusButtonProps {
  label: string;
  icon: any;
  className?: string;
}

const StatusButton: React.FC<StatusButtonProps> = ({ label, icon, className }) => (
  <div className={clsx(styles.container, className)}>
    {icon}
    <p className={styles.label}>{label}</p>
  </div>
);

export default StatusButton;
