import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, DataForUpdatingApplicationLoanManagement } from 'api/ApplicationDataApi';

export const createLoanManagementThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('/application/loanManagement', async (data: DataForUpdatingApplicationLoanManagement, thunkApi) => {
    try {
      await api.updateApplicationLoanManagementData(data);
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
