import { LinkSearchKindType } from 'enums/LinkSearchKindType';

import Api, { FetchMethodType } from './Api';

export interface Employer {
  id: string | null;
  kind: LinkSearchKindType;
  name: string;
  logo_url: string;
}

interface EncryptedConfigResponse {
  encrypted_config: string;
}

export interface ArgyleApi {
  getPayDistributionConfigsEncrypt(applicationId: string): Promise<EncryptedConfigResponse>;
}

export default class ArgyleApiRest extends Api implements ArgyleApi {
  public async getPayDistributionConfigsEncrypt(applicationId: string) {
    const data = await this.fetch<EncryptedConfigResponse>(`/argyle/payDistributionConfigsEncrypt/${applicationId}`, {
      method: FetchMethodType.GET,
    });

    return data;
  }
}
