import React, { FC } from 'react';

import countries from 'i18n-iso-countries';
import localeData from 'i18n-iso-countries/langs/en.json';
import clsx from 'clsx';
import InputSelect from 'components/InputSelect';
import { InputSelectOption } from 'components/InputSelect/InputSelect';

import styles from './CountrySelect.module.scss';

countries.registerLocale(localeData);

interface CountrySelectProps {
  onChange: (country: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  placeHolder?: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  name?: string;
}

const CountrySelect: FC<CountrySelectProps> = ({
  onChange,
  onBlur,
  className,
  placeHolder,
  label,
  value,
  disabled,
  name,
}) => {
  const options: InputSelectOption[] = [
    {
      label: 'United States of America',
      value: 'US',
    },
    ...Object.keys(countries.getAlpha2Codes()).map((code: string) => ({
      value: code,
      label: countries.getName(code, 'en'),
    })),
  ];

  return (
    <div className={clsx(styles.inputContainer, className)}>
      <InputSelect
        onChange={(option: InputSelectOption) => onChange && onChange(option.value)}
        className={clsx(styles.input, className)}
        placeholder={placeHolder}
        value={value}
        disabled={disabled}
        name={name}
        label={label}
        onBlur={onBlur}
        options={options}
      />
    </div>
  );
};

export default CountrySelect;
