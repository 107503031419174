import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { Debt, DebtRecommendation, DebtSummary, LoanTypeHumanized, Tradeline } from 'handlers/applicationData';

export interface Category {
  title: string;
  apr: number;
  accounts: Account[];
}

export interface Account {
  logo: string;
  name: string;
  amount: number;
}

export const REVIEW_STATUSES = [
  ApplicationStatusName.ManualReview,
  ApplicationStatusName.ManualVerify,
  ApplicationStatusName.FinalReview,
];

export const getTotalAmountForCategory = (tradelines: Tradeline[]): number =>
  tradelines.reduce((total: number, tradeline) => total + tradeline.balance, 0);

const debtColor: { [key in LoanTypeHumanized]: string } = {
  [LoanTypeHumanized.PersonalLoan]: '#D77EF7',
  [LoanTypeHumanized.CreditCard]: '#86B4F9',
  [LoanTypeHumanized.MedicalLoan]: '#ED69C0',
  [LoanTypeHumanized.StudentLoan]: '#7D89FA',
  [LoanTypeHumanized.AutoLoan]: '#49DED5',
  [LoanTypeHumanized.Mortgage]: '#ED69C0',
  [LoanTypeHumanized.Other]: '#ED69C0',
};

export const getSparkLineColor = (debtName: LoanTypeHumanized): string => debtColor[debtName];

interface DebtCategory {
  payOffDebts: Debt[];
  keepItDebts: Debt[];
}

export const getDebtCategories = (debtSummary: DebtSummary): DebtCategory => {
  const payOffDebts: Debt[] = [];
  const keepItDebts: Debt[] = [];

  Object.values(debtSummary).forEach((debt: Debt) => {
    if (debt.recommendation === DebtRecommendation.PayOff) {
      payOffDebts.push(debt);
    } else {
      keepItDebts.push(debt);
    }
  });

  return {
    payOffDebts,
    keepItDebts,
  };
};
