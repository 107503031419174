import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfessionGroup } from 'enums/ProfessionGroup';
import { getApplicationData } from 'thunks';

import { GetApplicationDataResponse } from './applicationData';

export interface ProfessionGroupData {
  professionGroup: ProfessionGroup;
}

export const initialState: ProfessionGroupData = {
  professionGroup: ProfessionGroup.Healthcare,
};

const professionGroupSlice = createSlice({
  name: 'professionGroup',
  initialState,
  reducers: {
    setProfessionGroup: (state: ProfessionGroupData, { payload }: PayloadAction<ProfessionGroup>) => {
      state.professionGroup = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      const {
        employment: { professionGroup },
      } = payload.application;
      if (professionGroup) {
        state.professionGroup = professionGroup;
      }
    });
  },
});

export const { setProfessionGroup } = professionGroupSlice.actions;

export default professionGroupSlice.reducer;
