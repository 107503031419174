import Api, { FetchMethodType } from 'api/Api';

import { PayrollProviderName } from './EmployersApi';

export interface PinwheelApi {
  createLinkToken(applicationId: string): Promise<PinwheelTokenResponse>;
}

export interface PinwheelCredentials {
  accountId: string;
  employerId: string;
  provider: PayrollProviderName;
}

interface PinwheelTokenResponse {
  token: string;
}

export interface PinwheelLinkTokenParams {
  employerId?: string;
  platformId?: string;
  jobType?: string;
  applicationId?: string;
  amount?: number;
  disableDirectDepositSplitting?: boolean;
  accountNumber?: string;
  routingNumber?: string;
  accountType?: string;
}

export default class PinwheelApiRest extends Api implements PinwheelApi {
  public async createLinkToken(applicationId: string) {
    return this.fetch<PinwheelTokenResponse>(`/pinwheel/linkToken/${applicationId}`, {
      method: FetchMethodType.GET,
    });
  }
}
