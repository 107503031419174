import { createSlice } from '@reduxjs/toolkit';
import { sendOfferComparisonEmail } from 'thunks';

const initialState = {
  offerComparison: {
    isLoading: false,
  },
};

const emailService = createSlice({
  name: 'emailService',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendOfferComparisonEmail.pending, (state) => {
      state.offerComparison.isLoading = true;
    });
    builder.addCase(sendOfferComparisonEmail.fulfilled, (state) => {
      state.offerComparison.isLoading = false;
    });
  },
});

export default emailService.reducer;
