import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YourEmployerVariable } from 'enums/LoanFormVariables';
import { getApplicationData, updateEmploymentData } from 'thunks';

import { GetApplicationDataResponse } from './applicationData';

export interface Employer {
  isConnected: boolean;
  provider: string;
  data: {
    [YourEmployerVariable.EmployerName]: string;
    [YourEmployerVariable.StartOfEmployment]: string;
    [YourEmployerVariable.JobTitle]: string;
    [YourEmployerVariable.AnnualIncome]: string;
    [YourEmployerVariable.PayFrequency]: string;
  };
}

interface TotalIncomeSchedule {
  [YourEmployerVariable.PayFrequency]: string;
}

export interface EmployerIncome {
  employer_1: {
    [YourEmployerVariable.AnnualIncome]: string;
    [YourEmployerVariable.PayFrequency]: string;
  };
  employer_2: {
    [YourEmployerVariable.AnnualIncome]: string;
    [YourEmployerVariable.PayFrequency]: string;
  };
  additional_income?: string;
}

export interface YourEmployerData {
  employer_1: Employer;
  employer_2: Employer;
  is_licensed_professional: boolean;
  additional_income?: string;
  isLoading?: boolean;
}

const employerInitialState: Employer = {
  isConnected: false,
  provider: '',
  data: {
    [YourEmployerVariable.EmployerName]: '',
    [YourEmployerVariable.StartOfEmployment]: '',
    [YourEmployerVariable.JobTitle]: '',
    [YourEmployerVariable.AnnualIncome]: '',
    [YourEmployerVariable.PayFrequency]: '',
  },
};

export const initialState: YourEmployerData = {
  employer_1: employerInitialState,
  employer_2: employerInitialState,
  is_licensed_professional: false,
  isLoading: false,
  additional_income: '',
};

const yourEmployer = createSlice({
  name: 'yourEmployer',
  initialState,
  reducers: {
    setYourTotalIncomeData: (state: YourEmployerData, { payload }: PayloadAction<TotalIncomeSchedule>) => {
      state.employer_1.data[YourEmployerVariable.PayFrequency] = payload[YourEmployerVariable.PayFrequency];
      analytics.identify({
        payFrequency: payload[YourEmployerVariable.PayFrequency],
      });
    },
    setYourEmployerData: (state: YourEmployerData, { payload }: PayloadAction<YourEmployerData>) => {
      const employer1 = payload.employer_1
        ? { ...state.employer_1.data, ...payload.employer_1.data }
        : state.employer_1.data;
      const employer2 = payload.employer_2
        ? { ...state.employer_2.data, ...payload.employer_2.data }
        : state.employer_2.data;

      analytics.identify({
        employerName1: employer1[YourEmployerVariable.EmployerName],
        employerName2: employer2[YourEmployerVariable.EmployerName],
      });

      return Object.assign(state, {
        employer_1: { ...state.employer_1, data: employer1 },
        employer_2: { ...state.employer_2, data: employer2 },
        is_licensed_professional: payload.is_licensed_professional,
        additional_income: payload.additional_income,
      });
    },
    clearSecondaryEmployer: (state: YourEmployerData) => {
      state.employer_2 = initialState.employer_2;
      analytics.identify({
        employerName2: null,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateEmploymentData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateEmploymentData.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateEmploymentData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      const { employment } = payload.application;
      const { employer1, employer2 } = employment ?? {};

      employer1 &&
        (state.employer_1.data = {
          ...employer1,
          [YourEmployerVariable.EmployerName]: employer1.employerName ?? '',
          [YourEmployerVariable.StartOfEmployment]: employer1.hireDate ?? '',
          [YourEmployerVariable.PayFrequency]: employer1.payFrequency ?? '',
          [YourEmployerVariable.AnnualIncome]: String(employer1.annualIncome) ?? '',
          [YourEmployerVariable.JobTitle]: employer1.jobTitle ?? '',
        });
      employer2 &&
        (state.employer_2.data = {
          ...employer2,
          [YourEmployerVariable.EmployerName]: employer2.employerName ?? '',
          [YourEmployerVariable.StartOfEmployment]: employer2.hireDate ?? '',
          [YourEmployerVariable.PayFrequency]: employer2.payFrequency ?? '',
          [YourEmployerVariable.AnnualIncome]: String(employer2.annualIncome) ?? '',
          [YourEmployerVariable.JobTitle]: employer2.jobTitle ?? '',
        });
    });
  },
});

export const { setYourTotalIncomeData, setYourEmployerData, clearSecondaryEmployer } = yourEmployer.actions;

export default yourEmployer.reducer;
