import useDropdown from 'hooks/useDropdown';
import React, { useEffect, useState } from 'react';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as ChevronIcon } from 'images/chevron.svg';
import clsx from 'clsx';
import { Debt, LoanType, LoanTypeHumanized } from 'handlers/applicationData';
import { ReactComponent as PersonalLoanIcon } from 'images/fc-bank.svg';
import { ReactComponent as CarLoanIcon } from 'images/fc-car-loan.svg';
import { ReactComponent as CreditCardIcon } from 'images/fc-credit-card.svg';
import { ReactComponent as MortgageIcon } from 'images/fc-mortgage.svg';
import { ReactComponent as StudentLoanIcon } from 'images/fc-student-loan.svg';

import { getSparkLineColor, getTotalAmountForCategory } from './yourFinancesUtils';

import styles from './YourFinances.module.scss';

const getDefaultIcon = (loanType: string) => {
  const loanTypeLogos = {
    [LoanType.PersonalLoan]: <PersonalLoanIcon height="22px" />,
    [LoanType.AutoLoan]: <CarLoanIcon height="17px" />,
    [LoanType.CreditCard]: <CreditCardIcon height="18px" />,
    [LoanType.Mortgage]: <MortgageIcon height="22px" />,
    [LoanType.StudentLoan]: <StudentLoanIcon height="22px" />,
  };

  return loanTypeLogos[loanType as keyof typeof loanTypeLogos];
};

const DebtRow = ({ debt, totalBalance, index }: { debt: Debt; totalBalance: number; index: number }) => {
  const { isOpen, handleToggleDropdown, contentRef, height } = useDropdown();

  const [sparkLineWidth, setSparkLineWidth] = useState<number>(0);

  useEffect(() => {
    setSparkLineWidth((getTotalAmountForCategory(debt.tradelines) / totalBalance) * 100);
  }, []);

  return (
    <div className={styles.row}>
      <div className={styles.rowHeader} onClick={handleToggleDropdown}>
        <div>
          <div className={styles.rowText}>
            <p className={styles.rowTitle}>{LoanTypeHumanized[debt.name as keyof typeof LoanTypeHumanized]}</p>
            <div className={styles.rowHeaderRight}>
              <p className={styles.rowApr}>{debt.apr}% APR</p>
              <p className={styles.rowBalance}>{formatMonetaryAmount(getTotalAmountForCategory(debt.tradelines))}</p>
            </div>
          </div>
          <div className={styles.sparkLine}>
            <div
              className={styles.sparkLineValue}
              style={{
                transitionDelay: `${index * 300 + 1000}ms`,
                width: `${sparkLineWidth}%`,
                background: getSparkLineColor(LoanTypeHumanized[debt.name as keyof typeof LoanTypeHumanized]),
              }}
            />
          </div>
        </div>
        <div>
          <ChevronIcon className={clsx(styles.dropdownIcon, { [styles.expanded]: isOpen })} />
        </div>
      </div>
      <div ref={contentRef} style={{ height }} className={styles.dropdownContent}>
        {debt.tradelines.map((tradeline, idx) => {
          const defaultIcon = getDefaultIcon(tradeline.loanType);

          return (
            <div className={styles.accountRow} key={`${tradeline.firm.trim()}-${idx}`}>
              <div>
                {tradeline.logoUrl ? (
                  <img width="40px" className={styles.accountLogo} src={tradeline.logoUrl} alt={tradeline.firm} />
                ) : (
                  <div className={clsx(styles.accountLogo, styles.defaultLogo)}>{defaultIcon}</div>
                )}
                <p className={styles.accountName}>{tradeline.firm}</p>
              </div>
              <p className={styles.accountAmount}>{formatMonetaryAmount(tradeline.balance)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DebtRow;
