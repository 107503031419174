import { ReactComponent as BankAccount } from 'images/verification-steps/bank-account-solid.svg';
import { ReactComponent as BriefCase } from 'images/verification-steps/brief-case-solid.svg';
import { ReactComponent as License } from 'images/verification-steps/license-solid.svg';
import { ReactComponent as Paystubs } from 'images/verification-steps/paystubs-solid.svg';
import EmploymentDetailsStep from 'components/Verification/Steps/EmploymentDetails/EmploymentDetails';
import UploadLicenseStep from 'components/Verification/Steps/UploadLicense/Alloy';
import UploadPaystubsStep from 'components/Verification/Steps/UploadPaystubs/UploadPaystubs';
import CheckingAccountStep from 'components/Verification/Steps/CheckingAccount/Plaid/Plaid';

import { VerificationStepComponent } from './VerificationNavigation/VerificationNavigation';

export enum VerificationStep {
  EmploymentDetails = 'employmentDetails',
  UploadPaystubs = 'paystubs',
  CheckingAccount = 'bankAccount',
  UploadLicense = 'kyc',
}

export interface VerificationStepProgress {
  employmentDetails: boolean;
  paystubs: boolean;
  bankAccount: boolean;
  kyc: boolean;
}

interface VerificationStepData {
  id: VerificationStep;
  name: string;
  component: React.FunctionComponent<VerificationStepComponent>;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const employmentDetails = {
  id: VerificationStep.EmploymentDetails,
  name: 'Employment Details',
  component: EmploymentDetailsStep,
  icon: BriefCase,
};

const uploadPaystubs = {
  id: VerificationStep.UploadPaystubs,
  name: 'Upload Paystubs',
  component: UploadPaystubsStep,
  icon: Paystubs,
};

const checkingAccount = {
  id: VerificationStep.CheckingAccount,
  name: 'Connect Checking Account',
  component: CheckingAccountStep,
  icon: BankAccount,
};

const uploadLicense = {
  id: VerificationStep.UploadLicense,
  name: "Take Driver's License Photo",
  component: UploadLicenseStep,
  icon: License,
};

export const verificationSteps: Readonly<VerificationStepData[]> = [
  employmentDetails,
  uploadPaystubs,
  checkingAccount,
  uploadLicense,
] as const;

export const getVerificationStep = (stepId: VerificationStep) =>
  verificationSteps.find(({ id }) => id === stepId) ?? employmentDetails;

export const getNextUncompletedStep = (
  verificationStepsStatus: VerificationStepProgress,
  currentStep: VerificationStep,
): VerificationStep => {
  let nextStep;
  const currentIndex = Object.keys(verificationStepsStatus).findIndex((step) => step === currentStep);
  const nextStepsList = Object.keys(verificationStepsStatus).slice(currentIndex + 1);

  nextStep = nextStepsList.find((step) => !verificationStepsStatus[step as VerificationStep]);

  if (!nextStep) {
    nextStep = Object.keys(verificationStepsStatus).find((step) => !verificationStepsStatus[step as VerificationStep]);
  }

  return (nextStep as VerificationStep) ?? VerificationStep.EmploymentDetails;
};
