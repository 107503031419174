import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferralName } from 'enums/Referrals';

interface Referrals {
  [ReferralName.ReferredBy]: string;
}

export const initialState: Referrals = {
  [ReferralName.ReferredBy]: '',
};

const referrals = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    setReferralApplicationId: (state: Referrals, { payload }: PayloadAction<string>) => {
      state[ReferralName.ReferredBy] = payload;
    },
  },
});

export const { setReferralApplicationId } = referrals.actions;

export default referrals.reducer;
