import React from 'react';
import { ReactComponent as ThankYouIcon } from 'images/thank-you-icon.svg';

import styles from './ThankYou.module.scss';

const ThankYou = () => (
  <div className={styles.container}>
    <div className={styles.thankYouIcon}>
      <ThankYouIcon />
    </div>
    <h1 className={styles.title}>Thank you!</h1>
  </div>
);

export default ThankYou;
