import { AboutYouResponse } from 'handlers/yourName';
import { YourDetailsResponse } from 'handlers/yourAddress';
import { YourIncomeResponse } from 'handlers/yourTotalIncome';
import { CreditReportArgyleResponse } from 'handlers/creditReport';
import { ArgyleCredentials } from 'handlers/argyle';
import { PreQualificationDeclineReason } from 'enums/PreQualificationDeclineReason';
import { PinwheelCredentials } from 'api/PinwheelApi';

import Api, { FetchMethodType } from './Api';
import { PayrollProviderName } from './EmployersApi';

export interface GetUserDataResponse {
  provider: PayrollProviderName;
  isLinkSupported: boolean;
  declineReason: PreQualificationDeclineReason;
  aboutYou: AboutYouResponse;
  yourDetails: YourDetailsResponse;
  yourIncome: YourIncomeResponse;
  creditReport: CreditReportArgyleResponse;
  employmentsDataForLos: Array<Record<string, string | number | undefined>>;
}

export type UserDataRequestCredentials = ArgyleCredentials | PinwheelCredentials;

export interface UserDataApi {
  getUserData(credentials: UserDataRequestCredentials): Promise<GetUserDataResponse>;
}

export default class UserDataApiRest extends Api implements UserDataApi {
  public async getUserData(credentials: UserDataRequestCredentials) {
    const data = await this.fetch<GetUserDataResponse>(`/userData`, {
      method: FetchMethodType.PUT,
      body: credentials,
    });

    return Object.assign(data, { provider: credentials.provider });
  }
}
