import { createAsyncThunk } from '@reduxjs/toolkit';
import { PreQualificationApi } from 'api/PreQualificationApi';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';

export const createUserLocationThunk = (api: PreQualificationApi) =>
  createAsyncThunk('location', async () => {
    const clientRegion = await api.getUserLocation();
    localStorage.setItem(LocalStorageKeyName.Location, JSON.stringify(clientRegion));
    return clientRegion;
  });

export default createUserLocationThunk;
