import React from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { FlowComponentType } from 'routes/FlowRouter';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { formatTextDate } from 'utils/dateUtils';

import styles from './MissedPayment.module.scss';

const MissedPayment = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { missedPaymentAmount, missedPaymentDate } = application;

  const date = new Date(missedPaymentDate!);

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="You've Missed a Payment">
        <p className={styles.text}>
          Your last payment of {formatMonetaryAmount(missedPaymentAmount, true)} was due on {formatTextDate(date)}.
        </p>
        <p className={styles.text}>
          We didn't receive via payroll deduction. Let's work together on getting you back on track with your loan
        </p>

        <Button onClick={handleNext}>Continue</Button>
      </FormContainer>
    </div>
  );
};

export default MissedPayment;
