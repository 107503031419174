import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import ThankYou from 'components/ThankYou';
import BackLink from 'components/BackLink';

import styles from './EmailNotification.module.scss';

const EmailNotificationAlert = () => {
  const { email, phoneNumber } = useSelector((state: RootState) => state.preQualificationDecline);

  return (
    <div>
      <BackLink />
      <div className={styles.container}>
        <ThankYou />
        <p className={styles.description}>
          <p>We will notify you at</p>
          <span className={styles.email}>{email || phoneNumber}</span>
        </p>
      </div>
    </div>
  );
};

export default EmailNotificationAlert;
