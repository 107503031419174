export enum ErrorType {
  Default,
  CantGenerateOffer,
  CantGetStatementFile,
  InvalidMethodAuthentication,
  CantGenerateCardApplication,
}

export enum ErrorParam {
  StatementFile = '__statement_file',
}
