import React from 'react';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';

import { SurveyQuestion, SurveyOption, Answer } from './surveyQuestions';

import styles from './ExitSurvey.module.scss';

interface QuestionProps {
  item: SurveyQuestion;
  answers: Answer;
  onClick: (question: string, option: SurveyOption) => void;
}

const Question = ({ item, answers, onClick }: QuestionProps) => (
  <div className={styles.survey}>
    <p className={styles.question}>{item.question}</p>
    {item.options.map((option, idx) => (
      <div onClick={() => onClick(item.question, option)} key={idx} className={styles.option}>
        {answers[item.question]?.includes(option.value) ? (
          <CheckIcon className={styles.greenCheck} />
        ) : (
          <div className={styles.checkbox} />
        )}
        <span className={styles.optionText}>{option.value}</span>
      </div>
    ))}
  </div>
);

export default Question;
