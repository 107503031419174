import React from 'react';
import Button, { ButtonType } from 'components/Button/Button';
import { ReactComponent as CopyIcon } from 'images/copy-icon.svg';
import { copyToClipboard } from 'utils/copyToClipboard';

import styles from './NextSteps.module.scss';

const NextSteps = () => {
  const supportEmail = 'support@planneryapp.com';

  const handleGetBack = () => {
    window.open('https://www.planneryapp.com/');
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Next steps</h1>
      <div>
        <div className={styles.descriptionContainer}>
          <p className={styles.header}>Pay off your debt right away</p>
          <p className={styles.subtitle}>
            Once the fund hits your account, make sure you pay off your expensive debt immediately
          </p>
        </div>
        <div className={styles.descriptionContainer}>
          <p className={styles.header}>Manage your loan</p>
          <p className={styles.subtitle}>We’ve sent an email with a link to access our loan management portal.</p>
          <br />
          <p className={styles.subtitle}>
            Make sure you receive our emails. Add{' '}
            <span className={styles.clip} onClick={() => copyToClipboard(supportEmail)}>
              {supportEmail} <CopyIcon className={styles.icon} />
            </span>{' '}
            to your safe sender list.
          </p>
        </div>
      </div>
      <Button type={ButtonType.Secondary} onClick={handleGetBack} className={styles.button}>
        Back to Plannery homepage
      </Button>
    </div>
  );
};

export default NextSteps;
