import Api, { FetchMethodType } from './Api';

export interface MakeACHPaymentResponse {
  success: boolean;
}

export interface MakeACHPaymentData {
  applicationId: string;
}

export interface PaymentApi {
  makeACHPayment(data: MakeACHPaymentData): Promise<MakeACHPaymentResponse>;
}

export default class ACHPaymentApi extends Api implements PaymentApi {
  async makeACHPayment(data: MakeACHPaymentData): Promise<MakeACHPaymentResponse> {
    return this.fetch<MakeACHPaymentResponse>('/ach/makePayment', {
      method: FetchMethodType.POST,
      body: data,
    });
  }
}
