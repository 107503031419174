import React, { CSSProperties } from 'react';

import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  progress: number;
}

const ProgressBar = ({ progress }: ProgressBarProps) => (
  <div className={styles.background}>
    <div className={styles.progress} style={{ '--progress': `${progress}%` } as CSSProperties} />
  </div>
);

export default ProgressBar;
