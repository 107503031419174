import React from 'react';
import clsx from 'clsx';
import Button from 'components/Button';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import Loader from 'components/Loader';
import useReferralProgram from 'components/CardFlow/ReferralProgram/useReferralProgram';
import CardPreview from 'components/ReferralProgram/CardPreview';
import RewardProgress from 'components/ReferralProgram/RewardProgressBar/RewardProgressBar';
import GiftExpandableCard from 'components/ReferralProgram/GiftExpandableCard';
import galesGiftImage from 'images/gales-gift.png';
import useReferralProgramData from 'components/ReferralProgram/useReferralProgramData';
import { ReferralProgramID } from 'handlers/cardData';
import { getPluralGroupTerm } from 'enums/ProfessionGroup';
import { getProfessionGroup } from 'selectors/professionGroup';

import styles from './ReferralProgramSummary.module.scss';

const PlanneryCardSummary = () => {
  const navigate = useNavigate();
  const { waitListPosition, isLoading, cardColor } = useSelector(getCardData);

  const { thanksLabel, programDisplayData, referredApplicants } = useReferralProgram();
  const { currentCount, totalCount, originalPosition } = useReferralProgramData();
  const { professionGroup } = useSelector(getProfessionGroup);

  const { id, progressBar, thanksLabel: variantThanksLabel } = programDisplayData;

  const handleClick = () => {
    navigate(RoutePath.ReferralProgram, {
      state: {
        fromYourFinances: true,
      },
    });
  };

  if (isLoading) {
    return (
      <div className={clsx(styles.container, styles.loader)}>
        <Loader color="#795af7" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.title}>Plannery Card</p>

      <p className={styles.subtitle}>
        You're {waitListPosition ? <span className={styles.bold}>#{waitListPosition}</span> : 'already'} on the
        waitlist.
      </p>

      <CardPreview cardColor={cardColor} showName />

      <p className={styles.thanksLabel}>
        {referredApplicants.length ? thanksLabel : ''}
        {variantThanksLabel}
      </p>

      {id === ReferralProgramID.Gales && (
        <GiftExpandableCard
          referralAmount={referredApplicants.length}
          referralsToComplete={3}
          image={galesGiftImage}
          title="Gales Shoes"
          subtitle={`Stylish shoes for ${getPluralGroupTerm(professionGroup)} on their feet`}
          highlighted="Retail value: $100-$120"
        />
      )}

      {progressBar && (
        <RewardProgress
          title={progressBar.title}
          showPositionLabel={progressBar.showPositionLabel}
          invertedCount={progressBar.inverted}
          showCount={progressBar.showCount}
          isCountMoney={progressBar.isCountMoney}
          totalCount={totalCount}
          currentCount={currentCount}
          originalPosition={originalPosition}
        />
      )}

      <Button className={styles.button} onClick={handleClick}>
        Refer {referredApplicants.length ? 'another' : 'a'} friend
      </Button>
    </div>
  );
};

export default PlanneryCardSummary;
