import React from 'react';
import { VerificationStep, verificationSteps } from 'components/Verification/verificationSteps';
import useVerificationSteps from 'components/Verification/useVerificationSteps';
import { ReactComponent as ArrowIcon } from 'images/arrow-next.svg';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';

import styles from './ReturningFlow.module.scss';

const ReturningFlow = () => {
  const { verificationStepsStatus } = useVerificationSteps();
  const navigate = useNavigate();

  const handleContinue = (stepId: VerificationStep) => {
    navigate(RoutePath.VerificationSteps, {
      state: {
        step: stepId,
      },
    });
  };

  return (
    <div className={styles.container}>
      {verificationSteps.map((step) => (
        <div key={step.id} className={styles.step} onClick={() => handleContinue(step.id)}>
          {verificationStepsStatus[step.id] ? (
            <CheckIcon className={styles.icon} />
          ) : (
            <step.icon className={styles.icon} />
          )}
          <div className={styles.name}>{step.name}</div>
          <ArrowIcon className={styles.arrowIcon} />
        </div>
      ))}
    </div>
  );
};

export default ReturningFlow;
