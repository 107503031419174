import React, { FC } from 'react';
import clsx from 'clsx';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { ReactComponent as BackLinkIcon } from 'images/back-arrow-icon.svg';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './BackLink.module.scss';

interface BackLinkProps {
  onClick?: () => void;
  className?: string;
}

const BackLink: FC<BackLinkProps> = ({ onClick, className }) => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const { themeStyles } = useLayoutTheme();

  const goBack = () => {
    analytics.track('Back Clicked');
    navigate(-1);
  };

  return navigationType !== 'REPLACE' || onClick ? (
    <div className={clsx(styles.backLink, className, themeStyles.backLink)} onClick={onClick || goBack}>
      <BackLinkIcon />
      <span className={styles.backLinkTitle}>Back</span>
    </div>
  ) : null;
};

export default BackLink;
