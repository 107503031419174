import React from 'react';

import RoundedCheckBox from './RoundedCheckBox';

import styles from './CheckingAccountRow.module.scss';

interface CheckingAccountRowProps {
  accountName: string | null;
  accountNumber: string;
  accountBalance: number;
  selected?: boolean;
  onClick: () => void;
}

const CheckingAccountRow = ({
  accountName,
  accountNumber,
  accountBalance,
  selected = false,
  onClick,
}: CheckingAccountRowProps) => (
  <div onClick={onClick} className={styles.row}>
    <RoundedCheckBox selected={selected} />
    <div className={styles.rowContent}>
      <div className={styles.heading}>
        <div className={styles.accountName}>{accountName}</div>
        <div className={styles.accountNumber}>(...{accountNumber})</div>
      </div>
      <div className={styles.accountBalance}>${accountBalance}</div>
    </div>
  </div>
);

export default CheckingAccountRow;
